import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { DispatchType } from '../../reducers';
import {
  resetError,
  resetPassword,
} from '../../actions/auth';
import ResetForm from '../../components/AuthForm/ResetForm';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import logo from '../../assets/images/logo_white.png';
import styles from './Auth.module.scss';

type FormValues = {
  password: string
};

export default function Reset() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, error } = useAppSelector((state: any) => state.authReducer);
  const { token } = useParams<{ token : string }>();
  const [succeed, setSucceed] = useState(false);
  const successMessage = 'Votre mot de passe a été changé.';

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (user?._id) {
      if (user.role === 'Admin') {
        navigate('/admin');
      }
    }
  }, [user]);

  function submit(data: FormValues) {
    resetPassword(dispatch, {
      token,
      password: data.password,
    }).then(() => {
      setSucceed(true);
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img
            src={logo}
            alt="logo"
          />
        </div>
        <ResetForm
          submit={(data) => submit(data)}
          errorMessage={error}
          successMessage={succeed ? successMessage : ''}
        />
      </div>
    </div>
  );
}
