import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DispatchType } from '../../../reducers';
import {
  getAllUsersAction,
} from '../../../actions/users';
// import { IUser } from '../../../types/auth.types';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import styles from './Users.module.scss';
import Loader from '../../../components/Loader';
import UserRow from '../../../components/UserRow/UserRow';
import { IUser } from '../../../types/auth.types';
import { getOrdersListAction } from '../../../actions/orders';

export default function Users({ role }: { role: string }) {
  const dispatch: DispatchType = useAppDispatch();
  const { usersList, isLoading } = useAppSelector((state: any) => state.userReducer);
  const addLink = role === 'Member' ? '/admin/customer/add' : '/admin/team/add';
  useEffect(() => {
    getAllUsersAction(dispatch, role);
  }, [role]);

  useEffect(() => {
    getOrdersListAction(dispatch);
  }, []);
  return (
    <div className={styles.container}>
      <header>
        <h2>{role === 'Owner' ? 'Mon équipe' : 'Mes clients'}</h2>
        <Link
          to={addLink}
          className={`${styles.btn} ${styles.primary}`}
        >
          {role === 'Owner' ? 'Ajouter un membre' : 'Ajouter un client'}
        </Link>
      </header>
      {isLoading
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <div className={styles.list}>
            {usersList?.map((u: IUser) => <UserRow key={u._id} user={u} />)}
          </div>
        )}
    </div>
  );
}
