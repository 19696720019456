import { Action } from '../types';
import {
  STRUCTURE_LOADING,
  STRUCTURE_ERROR,
  STRUCTURE_POST,
  STRUCTURE_GET_ALL,
} from '../actions/actions';
import { StructureState } from '../types/structure.types';

const initialState: StructureState = {
  structuresList: null,
  isLoading: false,
  error: null,
};

const structureReducer = (
  state: StructureState = initialState,
  action: Action,
): StructureState => {
  let updatedState = { ...state };
  let errorMessage;
  const { payload } = action;
  const list = state.structuresList || [];
  switch (action.type) {
    case STRUCTURE_LOADING:
      updatedState = {
        ...state,
        isLoading: true,
      };
      break;
    case STRUCTURE_POST:
      updatedState = {
        ...state,
        error: null,
        structuresList: [payload, ...list],
        isLoading: false,
      };
      break;
    case STRUCTURE_GET_ALL:
      updatedState = {
        ...state,
        structuresList: action.payload,
        isLoading: false,
      };
      break;
    case STRUCTURE_ERROR:
      errorMessage = action.payload;
      updatedState = {
        ...state,
        error: errorMessage,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default structureReducer;
