import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo_white.png';
import { DispatchType } from '../../reducers';
import {
  forgotPassword,
  resetError,
} from '../../actions/auth';
import { IForgotPassword } from '../../types/auth.types';
import ForgotForm from '../../components/AuthForm/ForgotForm';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import styles from './Auth.module.scss';

export default function Forgot() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, error } = useAppSelector((state: any) => state.authReducer);
  const [succeed, setSucceed] = useState(false);
  const successMessage = 'Un email contenant le lien pour modifié votre mot de passe vous a été envoyé';

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (user?._id) {
      if (user.role === 'Admin') {
        navigate('/admin');
      }
    }
  }, [user]);

  function submit(data: IForgotPassword) {
    forgotPassword(dispatch, data).then(() => {
      setSucceed(true);
    });
  }
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img
            src={logo}
            alt="logo"
          />
        </div>
        <ForgotForm
          submit={(data) => submit(data)}
          errorMessage={error}
          successMessage={succeed ? successMessage : ''}
        />
      </div>
    </div>
  );
}
