import React, { useState } from 'react';
import axios from 'axios';
import picture from '../../assets/images/input-picture.svg';
import { API_URL } from '../../actions';
import styles from './InputFile.module.scss';
import { IFile } from '../../types/file.types';

export default function InputFile({
  submit,
  id,
  className = '',
  btnText = 'Télécharger un fichier',
}:{
  submit: any
  id: string,
  btnText?: string,
  className?: string
}): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string|null|undefined>();
  function handleChange(selectorFiles: FileList | any) {
    if (selectorFiles && selectorFiles.length > 0) {
      const files = selectorFiles;
      console.log(files);
      if (id === 'thumbnail') {
        const thumbnail : any = selectorFiles[0];
        if (thumbnail.type !== 'image/jpeg' && thumbnail.type !== 'image/png') {
          return setErrorMessage('Le fichier requis dois étre au format png ou jpeg');
        }
      }
      setErrorMessage(null);
      const formData = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        formData.append('files', files[i]);
      }
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        const config: any = {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'multipart/form-data',
          },
        };
        axios.post(`${API_URL}/files`, formData, config)
          .then((response) => {
            resolve(response.data);
            submit(response.data.files.map((f: IFile) => f._id));
          })
          .catch((error) => {
            reject(error);
            setErrorMessage(error);
          });
      });
    }
    return null;
  }
  return (
    <div className={`${styles.container} ${className ? styles[className] : ''}`}>
      <input type="file" id={id} multiple onChange={(e : React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.files)} />
      {!className
      && (
      <label htmlFor={id} className={`${styles.btn} ${styles.secondary}`}>
        {btnText}
        {errorMessage && <p>{errorMessage}</p>}
      </label>
      )}
      {className
      && (
      <label htmlFor={id} className={`${className ? styles[className] : ''}`}>
        <img src={picture} alt={id} />
        <span>{btnText}</span>
        {errorMessage && <p>{errorMessage}</p>}
      </label>
      )}

    </div>
  );
}
