import React, { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import { API_URL } from '../../actions';
import { downloadFileAction, putFileAction } from '../../actions/orders';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { IFile } from '../../types/file.types';
import InputFile from '../InputFile/InputFile';
import styles from './FilesSection.module.scss';

export default function FilesSection({
  files,
  name,
  title,
  onSubmit,
  deleteFile,
  role,
} : {
  files: IFile[],
  name: string,
  title: string,
  role: string,
  onSubmit?: any,
  deleteFile: any
}) {
  const dispatch: DispatchType = useAppDispatch();
  const { order } = useAppSelector((state: any) => state.orderReducer);
  const [isOpen, setIsOpen] = useState(true);
  function downloadFile(path :string, fileName: string, fileId: string) {
    downloadFileAction(path, fileName);
    if (role !== 'Member') {
      putFileAction(dispatch, fileId, { isNewFile: false, orderId: order._id });
    }
  }
  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h3 className={styles.label}>
          {files?.length > 1 && files?.length}
          {' '}
          {title}
        </h3>
        <button
          type="button"
          className={!isOpen ? styles.active : ''}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={styles.minus} />
          <div className={styles.plus} />
        </button>
      </div>
      <div className={styles.files} style={{ display: !isOpen ? 'none' : 'block' }}>
        <div className={styles.list}>
          {files?.map((f: IFile) => (
            <div key={f._id} className={styles.file}>
              {name === 'files'
                && (
                <div className={`${styles.new} ${f.isNewFile ? styles.active : ''}`} />
                )}
              <a href={`${API_URL}/${f.path}`} target="_blank" rel="noreferrer">
                {f.mimetype === 'application/pdf'
                && (
                <div className={styles.img}>
                  <iframe title={f.name} scrolling="no" frameBorder="0" width="100%" height="100%" style={{ overflow: 'hidden' }} src={`${API_URL}/${f.path}`} />
                </div>
                )}
                {f.mimetype !== 'application/pdf'
                && (
                <div className={styles.img}>
                  <img src={`${API_URL}/${f.path}`} alt="img" />
                </div>
                )}
              </a>
              <div className={styles.actions}>
                <button
                  type="button"
                  className={styles.download}
                  onClick={() => downloadFile(`${API_URL}/${f.path}`, f.name, f._id)}
                >
                  <HiDownload size={20} />
                </button>
                {role === 'Member' && name === 'files'
                  && (
                  <button
                    type="button"
                    onClick={() => deleteFile(f._id)}
                    className={styles.delete}
                  >
                    <AiFillDelete size={20} />
                  </button>
                  )}
                {role !== 'Member' && name === 'pastBats'
                  && (
                  <button
                    type="button"
                    onClick={() => deleteFile(f._id)}
                    className={styles.delete}
                  >
                    <AiFillDelete size={20} />
                  </button>
                  )}
                {role !== 'Member' && name === 'thumbnail'
                  && (
                  <button
                    type="button"
                    onClick={() => deleteFile(f._id)}
                    className={styles.delete}
                  >
                    <AiFillDelete size={20} />
                  </button>
                  )}
              </div>
              <p>{f.name}</p>
            </div>
          ))}
          {role === 'Member' && name !== 'pastBats'
            && (
            <InputFile
              id={name}
              className="list"
              submit={(array: string[]) => onSubmit(array)}
            />
            )}
          {role !== 'Member' && name === 'thumbnail'
            && (
            <InputFile
              id={name}
              className="list"
              submit={(array: string[]) => onSubmit(array)}
            />
            )}
        </div>
      </div>
    </section>
  );
}
