import {
  configureStore,
} from '@reduxjs/toolkit';

import authReducer from './auth.reducer';
import userReducer from './user.reducer';
import structureReducer from './structure.reducer';
import orderReducer from './order.reducer';
import questionsReducer from './question.reducer';
import productReducer from './product.reducer';

export const reducers = configureStore({
  reducer: {
    authReducer,
    userReducer,
    structureReducer,
    orderReducer,
    questionsReducer,
    productReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `ApplicationState` and `DispatchType` types from the store itself
export type ApplicationState = ReturnType<typeof reducers.getState>;
export type DispatchType = typeof reducers.dispatch;
