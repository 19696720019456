import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import {
  getOrderAction, getOrdersListAction, postOrderAction, putOrderAction,
} from '../../../actions/orders';
import Loader from '../../../components/Loader';
import Contents from '../../../components/OrderForms/Contents';
import Formats from '../../../components/OrderForms/Formats';
import Infos from '../../../components/OrderForms/Infos';
import Recap from '../../../components/OrderForms/Recap';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import { DispatchType } from '../../../reducers';
import { IFormat, IOrder } from '../../../types/order.types';

import styles from './OrderForm.module.scss';

export const requiredRule = { required: 'Ce champs est requis' };

export default function OrderForm() {
  const navigate = useNavigate();
  const params = useParams();
  const {
    isLoading, order, lists, backUrl,
  } = useAppSelector((state: any) => state.orderReducer);
  const dispatch: DispatchType = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<IOrder>({
    defaultValues: {
      isPublic: true,
      type: 'Print',
      formats: [],
      files: [],
      deliveryDate: '',
      emails: [],
    },
  });

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const url = params?.id ? `/customer/order/form/${params?.id}` : '/customer/order/form';
  const step = query.get('step');
  const watchForm = watch();

  useEffect(() => {
    getOrdersListAction(dispatch);
    if (params?.id) {
      getOrderAction(dispatch, params?.id);
    }
    if (!params?.id && step && parseFloat(step) > 1) {
      navigate(`${url}?step=1`);
    }
  }, []);

  useEffect(() => {
    if (!order?._id) {
      setValue('structure', lists?.structures[0]);
    }
  }, [lists?.structures]);

  useEffect(() => {
    if (!order?._id && order?.eventType) {
      setValue('eventType', lists?.events.find((e: IOption) => e.value === order.eventType));
    }
  }, [order?.category, order?.eventType]);

  useEffect(() => {
    if (order?._id) {
      const orderForm = {
        ...order,
        deliveryDate: order?.deliveryDate ? new Date(order.deliveryDate) : '',
        emails: order?.emails.map((e :string) => ({ value: e, label: e })),
        eventType: lists?.events.find((e: IOption) => e.value === order.eventType),
        structure: lists?.structures.find((e: IOption) => e.value === order.structure._id),
        printType: lists?.printsLocations.find((e: IOption) => e.value === order.printType),
      };
      reset(orderForm);
    }
  }, [order, lists]);

  useEffect(() => {
    if (watchForm?.formats) {
      clearErrors('formats');
    }
  }, [watchForm?.formats]);

  useEffect(() => {
    if (watchForm?.content) {
      clearErrors('content');
    }
  }, [watchForm?.content]);

  function getFormPosition() {
    let position = 0;
    switch (step) {
      case '1':
        position = 0;
        break;
      case '2':
        position = 25;
        break;
      case '3':
        position = 50;
        break;
      case '4':
        position = 75;
        break;
      default:
        break;
    }
    return `translateX(-${position}%)`;
  }

  const onSubmit: SubmitHandler<IOrder> = (data) => {
    const form = {
      ...data,
      eventType: data?.eventType?.value || data.eventType,
      printType: data?.printType?.value || data.printType,
      emails: data?.emails && data?.emails?.length > 0 ? data?.emails.map((e: any) => e.value) : [],
      structure: data?.structure?.value || data.structure,
      formats: data?.formats && data?.formats?.length > 0 ? data.formats.map((f: IFormat) => ({
        type: f.type, size: f.size, quantity: f.quantity, format: f.format,
      })) : [],
    };
    if (data?.files) {
      form.files = data?.files.map((f: any) => f?._id || f);
    }
    if (order?._id) {
      return putOrderAction(dispatch, order._id, form);
    }
    return postOrderAction(dispatch, form);
  };

  return (
    <div className={styles.container}>
      <header>
        <Link to={backUrl || '/customer/order'} className={styles.back}>
          <HiOutlineArrowLeft size={20} />
          <p>Retour</p>
        </Link>
        <h2>Ma demande de brief</h2>
        <div className={styles.steps}>
          <p className={step === '1' ? styles.active : ''}>01 Informations</p>
          <p className={step === '2' ? styles.active : ''}>02 Formats</p>
          <p className={step === '3' ? styles.active : ''}>03 contenus</p>
          <p className={step === '4' ? styles.active : ''}>04 RECAP</p>
        </div>
      </header>
      {isLoading && !order?._id
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <form
            style={{ transform: getFormPosition() }}
          >
            <Infos
              errors={errors}
              control={control}
              requiredRule={requiredRule}
              onSubmit={handleSubmit(onSubmit)}
              setValue={setValue}
              watchForm={watchForm}
              url={url}
              lists={lists}
            />
            <Formats
              activeStep={step}
              errors={errors}
              control={control}
              requiredRule={requiredRule}
              onSubmit={handleSubmit(onSubmit)}
              setValue={setValue}
              setError={setError}
              watchForm={watchForm}
              url={url}
              lists={lists}
            />
            <Contents
              activeStep={step}
              onSubmit={onSubmit}
              setValue={setValue}
              watchForm={watchForm}
              setError={setError}
              errors={errors}
              url={url}
            />
            <Recap
              activeStep={step}
              onSubmit={onSubmit}
              watchForm={watchForm}
              url={url}
            />
          </form>
        )}
    </div>
  );
}
