import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useWatch } from 'react-hook-form';
import { FiCalendar } from 'react-icons/fi';
// GrFormView

import styles from './datepicker.module.scss';
import './datepicker.scss';

registerLocale('fr', fr);

// interface IInput {
//   onChange: () => void
//   placeholder: string | undefined,
//   value: string | number | undefined,
//   id: string,
//   onClick: () => void,
// }

const Input = forwardRef<HTMLInputElement, any>(({
  onChange,
  placeholder = '',
  value = '',
  id,
  onClick,
}, ref) => (
  <input
    ref={ref}
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
  />
));

Input.displayName = 'Input';

const StyledDatePicker = forwardRef<any, any>(({
  name,
  control,
  rules,
  placeholder = '',
  widthLabel = null,
  inline = false,
  label = null,
  minDate = null,
  selectsRange = false,
}, ref) => {
  const inputWatch = useWatch({
    control,
    name,
  });

  const [date, setDate] = useState();

  useEffect(() => {
    setDate(inputWatch);
  }, [inputWatch]);

  useImperativeHandle(ref, () => ({
    getValue: () => date,
    getObject: () => ({ [name]: date }),
  }));

  function isSameDay(date1: Date, date2: Date) {
    if (
      date1
      && date2
      && date1.getDate() === date2.getDate()
      && date1.getMonth() === date2.getMonth()
      && date1.getFullYear() === date2.getFullYear()
    ) {
      return true;
    } return false;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {
          value,
          ref: inputRef,
          onChange,
        },
      }) => (
        <div className={inline ? `container-custom-date-picker ${styles['date-picker']} ${styles.inline}` : `container-custom-date-picker ${styles['date-picker']}`}>
          { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <div className={`${styles['container-input']}`}>
            <DatePicker
              name={name}
              selected={selectsRange ? value[0] : value}
              onChange={(dateValue) => onChange(dateValue)}
              minDate={minDate}
              locale="fr"
              dateFormat="dd/MM/yyyy"
              placeholderText={placeholder}
              startDate={selectsRange ? value[0] : value}
              endDate={selectsRange ? value[1] : null}
              selectsRange={!!selectsRange}
              customInput={selectsRange ? null : <Input inputRef={inputRef} />}
              dayClassName={(d) => (`${styles.day} ${!selectsRange && isSameDay(d, value) ? styles.selected : ''}`)}
            />
            <div className={styles['container-icon']}>
              <FiCalendar />
            </div>
          </div>
        </div>
      )}
    />
  );
});

export default StyledDatePicker;
