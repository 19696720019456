// AUTH ACTIONS
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';
// USERS ACTIONS
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';
export const USER_GET_ALL = 'USER_GET_ALL';
export const USER_GET = 'USER_GET';
export const USER_PUT = 'USER_PUT';
export const USER_POST = 'USER_POST';
// STRUCTURES ACTIONS
export const STRUCTURE_LOADING = 'STRUCTURE_LOADING';
export const STRUCTURE_ERROR = 'STRUCTURE_ERROR';
export const STRUCTURE_GET_ALL = 'STRUCTURE_GET_ALL';
export const STRUCTURE_POST = 'STRUCTURE_POST';
// ORDER ACTIONS
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';
export const ORDER_GET_ALL = 'ORDER_GET_ALL';
export const ORDER_GET = 'ORDER_GET';
export const ORDER_PUT = 'ORDER_PUT';
export const ORDER_POST = 'ORDER_POST';
export const ORDER_GET_LISTS = 'ORDER_GET_LISTS';
export const ORDER_CATALOG = 'ORDER_CATALOG';
export const SET_ORDERS_FILTERS = 'SET_ORDERS_FILTERS';
export const SET_BACK_URL = 'SET_BACK_URL';
// FILES ACTIONS
export const FILE_LOADING = 'FILE_LOADING';
export const FILE_ERROR = 'FILE_ERROR';
export const FILE_POST = 'FILE_POST';
export const FILE_DELETE = 'FILE_DELETE';
// ORDER ACTIONS
export const QUESTION_LOADING = 'QUESTION_LOADING';
export const QUESTION_ERROR = 'QUESTION_ERROR';
export const GET_ORDER_QUESTIONS = 'GET_ORDER_QUESTIONS';
export const QUESTION_POST = 'QUESTION_POST';
export const QUESTION_PUT = 'QUESTION_PUT';
// PRODUCT ACTIONS
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_GET_ALL = 'PRODUCT_GET_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_POST = 'PRODUCT_POST';
