import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './QuestionRow.module.scss';
import { IComment, ICommentForm, IQuestion } from '../../types/question.types';
import Comment from '../Comment/Comment';
import { ErrorField, Textarea } from '../../lib/HooksFormFields';
import { postCommentAction, putQuestionAction } from '../../actions/questions';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';

export default function QuestionRow({
  question,
}:{
  question: IQuestion,
}) {
  const dispatch: DispatchType = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.authReducer);
  const newComment = question.newMessage && question.newMessage !== user?.role;
  const comments = question?.comments?.length > 0 && question?.comments?.sort((a: IComment, b: IComment) => (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ICommentForm>({
    defaultValues: {
      questionId: question._id,
    },
  });
  const [questionIsOpen, setQuestionIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  function onSubmit(values: ICommentForm) {
    postCommentAction(dispatch, values);
    reset();
  }

  useEffect(() => {
    if (questionIsOpen && newComment) {
      putQuestionAction(dispatch, question._id, { newMessage: '' });
    }
  }, [questionIsOpen, newComment]);

  return (
    <div className={styles.container}>
      {newComment
        && <div className={styles.indicator} />}
      <button
        type="button"
        className={`${styles.title} ${questionIsOpen ? styles.active : ''}`}
        onClick={() => setQuestionIsOpen(!questionIsOpen)}
      >
        <p>
          {question?.title}
        </p>
        <p className={styles.label}>
          {question.comments.length}
          {' '}
          message
          {question.comments.length > 1 && 's'}
        </p>
      </button>
      <div className={styles.content} style={{ display: !questionIsOpen ? 'none' : 'block' }}>
        <div ref={contentRef} className={styles.list}>
          {comments && comments.map((c: IComment) => <Comment key={c._id} comment={c} />)}
        </div>
        <form>
          <Textarea
            control={control}
            name="message"
            rules={{ required: 'le message est requis' }}
            className="primary"
            placeholder="Tapez votre commentaire..."
          />
          {errors?.message && <ErrorField message={errors?.message?.message} />}
          {watch('message')
          && (
            <button
              className={`${styles.btn} ${styles.primary}`}
              onClick={handleSubmit(onSubmit)}
            >
              Envoyer
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
