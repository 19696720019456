import React from 'react';
import { Link } from 'react-router-dom';
import { IProduct } from '../../types/product.types';
import styles from './ProductRow.module.scss';

export default function ProductRow({
  product,
  url,
}:{
  product: IProduct
  url: string,
}): JSX.Element {
  return (
    <Link to={`${url}/${product._id}`} className={styles.row}>
      <div className={styles.col}>
        <p>
          {product.title}
        </p>
      </div>
      <div className={`${styles.col} ${styles.event}`}>
        <p>{product.eventType}</p>
      </div>
    </Link>
  );
}
