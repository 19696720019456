import React, { useEffect } from 'react';
import { MdChevronRight, MdClose, MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../actions';
import { postOrderAction } from '../../actions/orders';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { IOption } from '../../lib/HooksFormFields/StyledSelect';
import { IOrder } from '../../types/order.types';
import styles from './CatalogueSlider.module.scss';

export default function CatalogueSlider({
  activeIndex,
  setActiveIndex,
  orders,
}:{
  activeIndex: any,
  setActiveIndex: any,
  orders: IOrder[],
}): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orderReducer = useAppSelector((state: any) => state.orderReducer);

  function handleChangeIndex(index: number) {
    let value = index;
    if (index === -1) {
      value = 0;
    } else if (value === orders.length) {
      value = orders.length - 1;
    }
    setActiveIndex(value);
  }

  // function addDays(days:number) {
  //   const result = new Date();
  //   result.setDate(result.getDate() + days);
  //   return result.toISOString();
  // }

  function duplicateOrder(order: IOrder) {
    const createOrder = {
      ...order,
      bats: [],
      pastBats: [],
      invoice: null,
      thumbnail: null,
      status: 'draft',
      deliveryDate: null,
    };
    postOrderAction(dispatch, createOrder);
  }
  useEffect(() => {
    if (orderReducer.order?._id && activeIndex >= 0) {
      navigate(`/customer/order/form/${orderReducer.order?._id}?step=1`);
    }
  }, [activeIndex, orderReducer.order?._id]);
  return (
    <div className={`${styles.container} ${activeIndex >= 0 ? styles.active : ''}`}>
      <button
        type="button"
        className={styles.close}
        onClick={() => setActiveIndex()}
      >
        <MdClose />
      </button>
      <button
        type="button"
        className={styles.arrow}
        onClick={() => handleChangeIndex(activeIndex - 1)}
      >
        <MdChevronLeft />
      </button>
      <button
        type="button"
        className={`${styles.arrow} ${styles.right}`}
        onClick={() => handleChangeIndex(activeIndex + 1)}
      >
        <MdChevronRight />
      </button>
      <div
        className={styles.slider}
        style={{ width: `${(orders.length + 1) * 100}%`, transform: `translateX(-${activeIndex * 100}vw)` }}
      >
        {orders.map((order:IOrder) => (
          <div key={order._id} className={styles.card}>
            {order.thumbnail
            && (

            <div className={styles.img}>
              <img src={`${API_URL}/${order.thumbnail.path}`} alt="img" />
              {orderReducer?.lists?.structures.find((s: IOption) => s.value === order.structure?._id)
              && (
              <button
                type="button"
                className={`${styles.btn} ${styles.primary}`}
                onClick={() => duplicateOrder(order)}
              >
                Demander une ré-impression
              </button>
              )}
            </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
