/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import {
  Controller,
} from 'react-hook-form';
import styles from './styled-select.module.scss';
import { style1 } from './style';

export interface IOption {
  value: string,
  label: string,
  __isNew__?: boolean,
}

interface ICreatable {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any,
  inline?: Boolean | undefined | null,
  isMulti?: Boolean | undefined | null,
  placeholder: string,
  label: string | null,
  className?: string | null,
  options: IOption[],
  onChange: any,
  createOption: any,
}

function StyledCreatable({
  name,
  control,
  rules,
  isMulti = false,
  inline = false,
  placeholder = '',
  className = 'primary',
  label = '',
  options = [],
  onChange,
  createOption,
}: ICreatable) {
  const handleChange = (
    newValue: OnChangeValue<IOption, true>,
    actionMeta: ActionMeta<IOption>,
  ) => {
    if (actionMeta.action === 'create-option') {
      const value = isMulti ? newValue.find((v) => v.__isNew__) : newValue;
      createOption(value);
    } else {
      onChange(newValue);
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${className ? styles[className] : ''} ${styles.inline}` : `${styles['container-select']} ${className ? styles[className] : ''}`}>
          { label && <label>{label}</label>}
          <div className={styles.input}>
            <CreatableSelect
              {...field}
              options={options}
              formatCreateLabel={(inputValue: string) => `ajouter l'option "${inputValue}"`}
              onChange={(v, a) => handleChange(v, a)}
              className={`${styles.select}`}
              isMulti={!!isMulti}
              placeholder={placeholder}
              styles={style1}
            />
          </div>
        </div>
      )}
    />
  );
}

export default StyledCreatable;
