import React from 'react';
import uniqid from 'uniqid';
import { Control } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import helpImg from '../../assets/images/help.png';
// import helpIcon from '../../assets/images/icons/help.svg';
import {
  DatePicker,
  ErrorField, StyledSelect,
} from '../../lib/HooksFormFields';
import {
  IFormat, IOrder, IOrderList, RequiredRule,
} from '../../types/order.types';
import FormatRow from '../FormatRow/FormatRow';
import styles from './OrdersForms.module.scss';

export default function Formats({
  activeStep,
  errors,
  control,
  requiredRule,
  onSubmit,
  watchForm,
  setValue,
  lists,
  setError,
  url,
}:{
  activeStep: string | null,
  errors: any
  control: Control<IOrder, any>
  requiredRule: RequiredRule,
  onSubmit: any,
  watchForm: any,
  setValue: any,
  setError: any,
  lists: IOrderList | null,
  url: string,
}) {
  const navigate = useNavigate();
  const handleSubmitStep = (step : string) => {
    if (step === '1') {
      navigate(`/customer/order/form?step=${step}`);
    }
    if (!watchForm?.formats.find((f: IFormat) => f.format === 'print')) {
      setValue('printType', null);
    }
    if (step === '3') {
      if (watchForm.formats.length === 0) {
        setError('formats', { type: 'custom', message: 'Les formats sont requis' });
      } else if (watchForm.title
        && watchForm.eventType
        && watchForm.structure
        && watchForm.deliveryDate
        && watchForm.formats) {
        navigate(`${url}?step=${step}`);
      } else {
        onSubmit(watchForm);
      }
    }
  };

  function getMinDeliveryDate() {
    const date = new Date();
    const delivery = new Date();
    delivery.setDate(date.getDate() + 35);
    return delivery;
  }
  return (
    <div className={styles.container}>
      {activeStep === '2'
        && (
        <div className={styles.form}>

          <div className={styles.formats}>
            <div className={styles.list}>
              {lists && watchForm?.formats.length > 0
              && (
              <div className={styles.label}>
                <p>Format</p>
                {' '}
              </div>
              )}
              {lists && watchForm?.formats?.map((f: IFormat) => (
                <FormatRow
                  key={f._id}
                  format={f}
                  form={watchForm}
                  requiredRule={requiredRule}
                  lists={lists}
                  handleChangeFormats={(arr: IFormat[]) => setValue('formats', arr)}
                />
              ))}
            </div>
            {errors.formats && <ErrorField message={errors.formats.message} />}
            <button
              type="button"
              onClick={() => setValue('formats', [...watchForm.formats, {
                type: '', quantity: '', size: '', format: 'web', _id: uniqid(),
              }])}
              className={`${styles.add} ${styles.secondary}`}
            >
              Ajouter un format web
            </button>
            <button
              type="button"
              onClick={() => setValue('formats', [...watchForm.formats, {
                type: '', quantity: '', size: '', format: 'print', _id: uniqid(),
              }])}
              className={`${styles.add} ${styles.secondary}`}
            >
              Ajouter un format Print
            </button>
          </div>

          {watchForm?.formats.find((f: IFormat) => f.format === 'print')
          && (
            <div className={styles['container-field']}>
              <StyledSelect
                name="printType"
                control={control}
                rules={{ }}
                label="Impression"
                placeholder="Séléctionner..."
                options={lists?.printsLocations || []}
              />
            </div>
          )}

          <div className={styles['container-field']}>
            <DatePicker
              name="deliveryDate"
              control={control}
              label="Date limite de remise"
              minDate={getMinDeliveryDate()}
              rules={requiredRule}
            />
            {errors.deliveryDate && <ErrorField message={errors.deliveryDate.message} />}
          </div>
          <div className={styles['btn-container']}>
            <button
              className={`${styles.btn} ${styles.primary} ${styles.outlined}`}
              type="button"
              onClick={() => handleSubmitStep('1')}
            >
              Précèdent
            </button>
            <button
              className={`${styles.btn} ${styles.primary}`}
              type="button"
              onClick={() => handleSubmitStep('3')}
            >
              Suivant
            </button>
          </div>
        </div>
        )}
      <div className={styles.help} />
    </div>
  );
}
