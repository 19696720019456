import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import useDimensions from 'use-react-dimensions';
import { Provider } from 'react-redux';
import { reducers } from './reducers';
import Routes from './Routes';
import Mobile from './pages/Mobile/Mobile';

function App() {
  const { ref, dimensions } = useDimensions<HTMLDivElement>({});
  return (
    <Provider store={reducers}>
      <div ref={ref} className="App">
        {dimensions.width >= 1024 && dimensions.height >= 500
          ? (
            <Router>
              <Routes />
            </Router>
          )
          : <Mobile />}
      </div>
    </Provider>
  );
}

export default App;
