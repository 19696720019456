import React from 'react';
import styles from './CustomConfirm.module.scss';

export default function CustomConfirm({ message, btnText, action }:{ message: string, btnText: string, action: () => void }) {
  return (
    <div className={styles.container}>
      <p>{message}</p>
      <button
        type="button"
        className={`${styles.btn} ${styles.danger}`}
        onClick={() => action()}
      >
        {btnText}
      </button>
    </div>
  );
}
