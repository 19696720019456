import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import Loader from '../../../components/Loader';
import { DispatchType } from '../../../reducers';
import { getOrdersListAction } from '../../../actions/orders';
import { getProductsAction } from '../../../actions/products';
import { StyledSelect } from '../../../lib/HooksFormFields';
import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import { IProduct } from '../../../types/product.types';
import ProductRow from '../../../components/ProductRow/ProductRow';

import styles from './ProductsList.module.scss';

interface IFilters {
  event: IOption,
}

export default function ProductsList() {
  const { user } = useAppSelector((state: any) => state.authReducer);
  const url = user.role === 'Admin' ? '/admin/product' : '/owner/product';

  const { lists } = useAppSelector((state: any) => state.orderReducer);
  const { productsList, isLoading } = useAppSelector((state: any) => state.productReducer);
  const [products, setProducts] = useState([]);
  const allStatus = { label: 'TOUS', value: 'all' };
  const {
    control,
    watch,
  } = useForm<IFilters>({
    defaultValues: {
      event: allStatus,
    },
  });
  const dispatch: DispatchType = useAppDispatch();
  const event = watch('event')?.value;

  useEffect(() => {
    getOrdersListAction(dispatch);
    getProductsAction(dispatch);
  }, []);

  useEffect(() => {
    if (productsList.length) {
      const filtered = productsList.filter((p: IProduct) => {
        if (event === 'all') {
          return p;
        }
        return p.eventType === event ? p : null;
      });
      setProducts(filtered);
    }
  }, [productsList, event]);

  return (
    <div className={styles.container}>
      <header>
        <Link
          to={`${url}/add`}
          className={`${styles.btn} ${styles.primary}`}
        >
          Ajouter une vignette
        </Link>
      </header>
      {isLoading
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <div className={styles.content}>
            <div>
              <div className={styles.header}>
                <div className={styles.title}>
                  <h2>Catalogue libre</h2>
                  <Link to={`${url}/catalog`} className={styles.link}>
                    <HiOutlineArrowRight />
                    voir le catalogue
                  </Link>
                </div>
                <div className={styles.filters}>
                  <p>Filtrer par event :</p>
                  <div className={styles['container-field']}>
                    <StyledSelect
                      name="event"
                      control={control}
                      rules={{ }}
                      className="secondary"
                      placeholder="Séléctionner..."
                      options={lists?.events
                        ? [allStatus, ...lists.events,
                        ] : []}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.list}>
                {products?.map((p: IProduct) => (
                  <ProductRow
                    key={p._id}
                    product={p}
                    url={url}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
