import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo_white.png';
import { DispatchType } from '../../reducers';
import {
  signIn,
  resetError,
  getProfileAction,
} from '../../actions/auth';
import { ILogin } from '../../types/auth.types';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import LoginForm from '../../components/AuthForm/LoginForm';

import styles from './Auth.module.scss';

export default function Login() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, error } = useAppSelector((state: any) => state.authReducer);

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (user?._id) {
      if (user.role === 'Admin') {
        navigate('/admin/orders');
      }
      if (user.role === 'Member') {
        navigate('/customer/orders');
      }
      if (user.role === 'Owner' || user.role === 'Moderator') {
        navigate('/owner/orders');
      }
      getProfileAction(dispatch);
    }
  }, [user]);

  function submit(data: ILogin) {
    signIn(dispatch, data);
  }
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img
            src={logo}
            alt="logo"
          />
        </div>
        <LoginForm
          submit={(data) => submit(data)}
          errorMessage={error}
        />
      </div>
    </div>
  );
}
