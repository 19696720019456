import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { SET_TOAST } from '../../actions/actions';
import styles from './toaster.module.scss';

export default function Toaster() {
  const dispatch: DispatchType = useAppDispatch();
  const { toast } = useAppSelector((state: any) => state.authReducer);

  useEffect(() => {
    if (toast?.message) {
      setTimeout(() => {
        dispatch({
          type: SET_TOAST,
          payload: null,
        });
      }, 4000);
    }
  }, [toast, dispatch]);

  return (
    <div className={`${styles.toaster} ${toast && toast.message ? styles.active : ''} ${toast?.type ? styles[toast.type] : ''}`}>
      <p>{toast && toast.message ? toast.message : ''}</p>
    </div>
  );
}
