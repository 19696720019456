import React, { useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { AiFillDelete } from 'react-icons/ai';
import { HiOutlineArrowLeft, HiDownload } from 'react-icons/hi';
import {
  Link, useParams, useNavigate,
} from 'react-router-dom';
import {
  deleteFileAction,
  deleteOrderAction,
  downloadFileAction,
  getOrderAction, getOrdersListAction, putFileAction, putOrderAction,
} from '../../../actions/orders';
import Loader from '../../../components/Loader';
import Status from '../../../components/Status/Status';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
// import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import { DispatchType } from '../../../reducers';
import { IFormat } from '../../../types/order.types';
import styles from './Order.module.scss';
import { InputText, StyledCreatable, StyledSelect } from '../../../lib/HooksFormFields';
import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import InputNumber from '../../../lib/HooksFormFields/InputNumber';
import InputFile from '../../../components/InputFile/InputFile';
import { IFile } from '../../../types/file.types';
import { API_URL } from '../../../actions';
import ModalStandalone, { IModalHandle } from '../../../lib/ModalStandalone';
import { getOrderQuestionAction } from '../../../actions/questions';
import QuestionsList from '../../../components/QuestionsList/QuestionsList';
import QuestionForm from '../../../components/QuestionForm/QuestionForm';
import FilesSection from '../../../components/FilesSection/FilesSection';
import { requiredRule } from '../OrderForm/OrderForm';
import { ORDER_GET } from '../../../actions/actions';

export default function Order() {
  const navigate = useNavigate();
  const modalRef = useRef<IModalHandle>(null);
  const params = useParams();
  const { user } = useAppSelector((state: any) => state.authReducer);
  const { questionsList } = useAppSelector((state: any) => state.questionsReducer);
  const { order, lists, backUrl } = useAppSelector((state: any) => state.orderReducer);
  const dispatch: DispatchType = useAppDispatch();
  const orderId = params?.id;
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<any>({
    defaultValues: {
      isPublic: false,
      type: 'Print',
      formats: [],
      files: [],
    },
  });

  const status = watch('status')?.value;
  const watchOrder = watch();
  const getStatusList = () => {
    if (!lists?.status) return [];
    let statusList = [...lists.status];
    if (user.role === 'Owner') {
      statusList = [...lists.status.filter((s: IOption) => s.value !== 'new' && s.value !== 'archived')];
    }
    return statusList;
  };

  useEffect(() => {
    getOrdersListAction(dispatch);
    return () => {
      dispatch({
        type: ORDER_GET,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (orderId) {
      getOrderAction(dispatch, orderId);
      getOrderQuestionAction(dispatch, orderId);
    }
  }, [orderId]);

  console.log(orderId);

  useEffect(() => {
    if (order?._id) {
      const orderForm = {
        ...order,
        deliveryDate: new Date(order.deliveryDate),
        status: lists?.status.find((e: IOption) => e.value === order.status),
        eventType: lists?.events.find((e: IOption) => e.value === order.eventType),
        structure: lists?.structures.find((e: IOption) => e.value === order.structure._id),
        printType: lists?.printsLocations.find((e: IOption) => e.value === order.printType),
        emails: order?.emails?.map((e: string) => ({ value: e, label: e })) || [],
      };
      reset(orderForm);
    }
  }, [order, lists]);

  const files = watchOrder.files?.map((f: IFile) => f._id);
  const bats = watchOrder.bats?.map((f: IFile) => f._id);
  const pastBats = watchOrder.pastBats?.map((f: IFile) => f._id);
  const emailsOptions = lists?.emails?.map((e: any) => ({ value: e.email, label: e.email })) || [];

  const onSubmit: SubmitHandler<any> = (data) => {
    const form = {
      ...data,
      status: data?.status?.value || data.status,
      eventType: data?.eventType?.value || data.eventType,
      printType: data?.printType?.value || data.printType,
      structure: data?.structure?.value || data.structure,
    };
    if (data?.files) {
      form.files = data?.files.map((f: any) => f?._id || f);
    }
    if (data?.bats) {
      form.bats = data?.bats.map((f: any) => f?._id || f);
    }
    if (data?.emails) {
      form.emails = data?.emails.map((f: any) => f?.value || f);
    }
    if (data?.pastBats) {
      form.pastBats = data?.pastBats.map((f: any) => f?._id || f);
    }
    if (data?.thumbnail) {
      form.thumbnail = form.thumbnail?._id || form.thumbnail;
    }
    return putOrderAction(dispatch, order._id, form);
  };

  function downloadFile(path :string, fileName: string, fileId: string) {
    downloadFileAction(path, fileName);
    if (user.role === 'Member') {
      putFileAction(dispatch, fileId, { isNewFile: false, orderId: order._id });
    }
  }

  function deleteFile(id: string) {
    if (files?.find((f: string) => f === id)) {
      deleteFileAction(dispatch, id);
      return onSubmit({ files: files?.filter((f: string) => f !== id) });
    }
    if (bats?.find((f: string) => f === id)) {
      return onSubmit({
        bats: bats?.filter((f: string) => f !== id),
        pastBats: [...pastBats, id],
      });
    }
    if (pastBats?.find((f: string) => f === id)) {
      deleteFileAction(dispatch, id);
      return onSubmit({ pastBats: pastBats?.filter((f: string) => f !== id) });
    }
    if (watchOrder.thumbnail?._id === id) {
      deleteFileAction(dispatch, id);
      return onSubmit({ ...watchOrder, thumbnail: null });
    }
    return null;
  }

  function addFile(name: string, id: string) {
    if (name === 'invoice') {
      if (watchOrder?.invoice?._id) {
        deleteFileAction(dispatch, watchOrder?.invoice?._id);
      }
      onSubmit({ ...watchOrder, invoice: id });
    }
    if (name === 'thumbnail') {
      if (watchOrder?.thumbnail?._id) {
        deleteFileAction(dispatch, watchOrder?.thumbnail?._id);
      }
      onSubmit({ ...watchOrder, thumbnail: id });
    }
  }

  function refuseBrief() {
    onSubmit({ ...watchOrder, status: 'draft' });
    navigate('/customer/order');
  }

  async function deleteOrder() {
    // eslint-disable-next-line no-alert
    if (window.confirm("Confirmer la suppression de l'archive")) {
      deleteOrderAction(dispatch, order._id);
      navigate('/customer/archived');
    }
  }

  return (
    <div className={styles.container}>
      {!order?._id
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <form className={styles.order}>
            <header>
              <div className={styles.left}>
                <Link to={backUrl || '/customer/order'} className={styles.back}>
                  <HiOutlineArrowLeft size={20} />
                  <p>Retour</p>
                </Link>
                <h2>{order.title}</h2>
                {user.role === 'Member'
                  ? (
                    <div className={styles.status}>
                      <Status status={order.status} />
                    </div>
                  )
                  : (
                    <div className={`${styles['container-field']} ${styles['status-field']} ${styles[status]}`}>
                      <StyledSelect
                        name="status"
                        control={control}
                        rules={{ }}
                        className="secondary"
                        placeholder="Séléctionner..."
                        options={getStatusList() || []}
                      />
                    </div>
                  )}
              </div>
              <div className={styles.right}>
                <div className={styles['btn-container']}>
                  {user.role !== 'Member' && order?.status === 'archived'
                  && (
                    <button
                      type="button"
                      className={`${styles.btn} ${styles.danger} ${styles.outline}`}
                      onClick={() => deleteOrder()}
                    >
                      Supprimer
                    </button>
                  )}
                  {(user.role === 'Moderator' || user.role === 'Admin') && status === 'new'
                  && (
                    <button
                      type="button"
                      className={`${styles.btn} ${styles.primary} ${styles.outline}`}
                      onClick={() => refuseBrief()}
                    >
                      Refuser le brief
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => modalRef?.current && modalRef.current.open()}
                    className={`${styles.btn} ${styles.primary} ${styles.outline}`}
                  >
                    {user.role === 'Member' ? 'Demande de modifications' : 'Poser une question'}
                  </button>
                  {user.role !== 'Member'
                  && (
                    <button
                      type="button"
                      className={`${styles.btn} ${styles.primary}`}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Enregister
                    </button>
                  )}
                  {user.role === 'Member' && status === 'send'
                  && (
                    <button
                      type="button"
                      className={`${styles.btn} ${styles.success}`}
                      onClick={() => onSubmit({ ...watchOrder, status: 'delivered' })}
                    >
                      Valider
                    </button>
                  )}
                  {user.role === 'Member' && status === 'delivered'
                  && (
                    <button
                      type="button"
                      className={`${styles.btn} ${styles.primary}`}
                      onClick={() => onSubmit({ ...watchOrder, status: 'archived' })}
                    >
                      Archiver
                    </button>
                  )}
                </div>
              </div>
            </header>
            <div className={styles.content}>
              <div className={styles.infos}>
                <div>
                  <label>Etablissement</label>
                  <p>{order?.structure?.name}</p>
                </div>
                <div>
                  <label>Date limite de remise</label>
                  <p>{order.deliveryDate && format(new Date(order.deliveryDate), 'dd/MM/yy')}</p>
                </div>
                {order.formats
                && (
                <div>
                  <label>Formats</label>
                  <p>
                    {order.formats.map((f : IFormat) => (
                      <span key={`dim-${f._id}`}>
                        <span>
                          {f?.type}
                          {f?.quantity && ` - ${f.quantity} ex`}
                        </span>
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
                )}
                {order?.printType
                  && (
                  <div>
                    <label>Impression</label>
                    <p>{order?.printType}</p>
                  </div>
                  )}
                <div>
                  <label>Partage</label>
                  <p>{order.isPublic ? 'Public' : 'Privé'}</p>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>
                  <h3>PROPOSITION GRAPHIQUE</h3>
                  <div className={styles.files}>
                    {watchOrder?.bats?.length === 0 && user.role !== 'Member'
                      && (
                      <InputFile
                        id="bats"
                        className="drag"
                        submit={(array: string[]) => onSubmit({ bats: [...bats, ...array], status: 'send' })}
                      />
                      )}
                    <div className={`${styles.list} ${styles.bats}`}>
                      {watchOrder?.bats?.map((f: IFile) => (
                        <div key={f._id} className={`${styles.file}`}>
                          <div className={`${styles.new} ${f.isNewFile ? styles.active : ''}`} />
                          <a href={`${API_URL}/${f.path}`} target="_blank" rel="noreferrer">
                            {f.mimetype === 'application/pdf'
                            && (
                            <div className={styles.img}>
                              <iframe title={f.name} scrolling="no" frameBorder="0" width="100%" height="100%" style={{ overflow: 'hidden' }} src={`${API_URL}/${f.path}#toolbar=0`} />
                            </div>
                            )}
                            {f.mimetype !== 'application/pdf'
                            && (
                            <div className={styles.img}>
                              <img src={`${API_URL}/${f.path}`} alt="img" />
                            </div>
                            )}
                          </a>
                          <div className={styles.actions}>
                            {user.role === 'Member'
                              && (
                              <button
                                type="button"
                                className={styles.download}
                                onClick={() => downloadFile(`${API_URL}/${f.path}`, f.name, f._id)}
                              >
                                <HiDownload size={20} />
                              </button>
                              )}
                            {user.role !== 'Member'
                            && (
                            <button
                              type="button"
                              onClick={() => deleteFile(f._id)}
                              className={styles.delete}
                            >
                              <AiFillDelete size={20} />
                            </button>
                            )}
                          </div>
                        </div>
                      ))}
                      {watchOrder?.bats?.length > 0 && user.role !== 'Member'
                        && (
                        <InputFile
                          id="bats"
                          className="list"
                          submit={(array: string[]) => onSubmit({ bats: [...bats, ...array], status: 'send' })}
                        />
                        )}
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <section>
                    <h3>TEXTE</h3>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: order.content }} />
                  </section>
                  {order?.printType === 'Imprimeur' && (user.role === 'Moderator' || user.role === 'Admin')
                  && (
                    <section>
                      <h3>IMPRIMEUR</h3>
                      <div className={styles.row}>
                        <div className={styles.input}>
                          <InputText
                            name="printer.name"
                            control={control}
                            rules={{ }}
                            className="primary"
                            label="Nom"
                            placeholder="Nom..."
                          />
                        </div>
                        <div className={styles.input}>
                          <InputNumber
                            name="printer.amount"
                            control={control}
                            rules={{ }}
                            className="primary"
                            label="Montant"
                            placeholder="montant..."
                          />
                        </div>
                      </div>
                    </section>
                  )}
                  {questionsList?.length > 0 && <QuestionsList questions={questionsList} />}
                  <FilesSection
                    files={watchOrder?.files}
                    name="files"
                    title="Fichiers"
                    role={user.role}
                    deleteFile={(id: string) => deleteFile(id)}
                    onSubmit={(array: string[]) => onSubmit({ files: [...files, ...array] })}
                  />
                  {watchOrder?.pastBats?.length > 0
                    && (
                    <FilesSection
                      files={watchOrder?.pastBats}
                      name="pastBats"
                      title="Dernières propositions"
                      role={user.role}
                      deleteFile={(id: string) => deleteFile(id)}
                    />
                    )}
                  {user.role !== 'Member' && watchOrder.isPublic
                    && (
                    <FilesSection
                      files={watchOrder?.thumbnail ? [watchOrder?.thumbnail] : []}
                      name="thumbnail"
                      title="Miniature catalogue"
                      role={user.role}
                      deleteFile={(id: string) => deleteFile(id)}
                      onSubmit={(array: string[]) => addFile('thumbnail', array[0])}
                    />
                    )}
                  <section>
                    <h3>CONTACTS</h3>
                    <StyledCreatable
                      name="emails"
                      control={control}
                      onChange={(opt: IOption[]) => setValue('emails', opt)}
                      createOption={(opt: IOption) => setValue('emails', [...watchOrder.emails, opt])}
                      rules={requiredRule}
                      isMulti
                      label="Mail des personnes à notifier"
                      placeholder="Ajouter des emails..."
                      options={emailsOptions}
                    />
                  </section>

                </div>
              </div>
            </div>
            <ModalStandalone ref={modalRef}>
              <QuestionForm orderId={order._id} close={() => modalRef.current?.close()} />
            </ModalStandalone>
          </form>
        )}
    </div>
  );
}
