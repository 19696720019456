import axios from 'axios';
import fileDownload from 'js-file-download';
import { DispatchType } from '../reducers';
import {
  deleteData, getData, postData, putData,
} from './index';
import {
  ORDER_LOADING,
  ORDER_ERROR,
  ORDER_GET_ALL,
  ORDER_GET,
  ORDER_PUT,
  SET_TOAST,
  ORDER_POST,
  ORDER_GET_LISTS,
  ORDER_CATALOG,
} from './actions';
import { IOrder } from '../types/order.types';
import { IFile } from '../types/file.types';

export const getOrdersListAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/order/lists';
  let lists = {};
  dispatch({
    type: ORDER_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(ORDER_ERROR, url, dispatch, true).then((response: any) => {
    lists = response.data.lists;
  });

  dispatch({
    type: ORDER_GET_LISTS,
    payload: lists,
  });
  return promise;
};

export const getOrdersAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/order/';
  let orders = {};
  dispatch({
    type: ORDER_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(ORDER_ERROR, url, dispatch, true).then((response: any) => {
    orders = response.data.orders;
  });

  dispatch({
    type: ORDER_GET_ALL,
    payload: orders,
  });
  return promise;
};

export const getOrdersCatalogAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/order/catalog';
  let orders = {};
  dispatch({
    type: ORDER_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(ORDER_ERROR, url, dispatch, true).then((response: any) => {
    orders = response.data.orders;
  });

  dispatch({
    type: ORDER_CATALOG,
    payload: orders,
  });
  return promise;
};

export const getOrderAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/order/${id}`;
  let order = {};
  dispatch({
    type: ORDER_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(ORDER_ERROR, url, dispatch, true).then((response: any) => {
    order = response.data.order;
  });

  dispatch({
    type: ORDER_GET,
    payload: order,
  });
  return promise;
};

export const putOrderAction = async (dispatch: DispatchType, id: string, data: IOrder): Promise<any> => {
  const url = `/order/${id}`;
  dispatch({
    type: ORDER_LOADING,
  });
  let order: IOrder | null | never = null;
  const states = ['accepted', 'pending', 'send'];
  const promise = await putData(ORDER_PUT, ORDER_ERROR, url, dispatch, data, true).then((response : any) => {
    order = response.order;
    if (states.find((s) => s === response.order.status)) {
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'success',
          message: 'la commande à été modifiée',
        },
      });
    }
  });
  dispatch({
    type: ORDER_PUT,
    payload: order,
  });
  return promise;
};

export const postOrderAction = async (dispatch: DispatchType, data: IOrder): Promise<any> => {
  const url = '/order';
  dispatch({
    type: ORDER_LOADING,
  });
  let order: IOrder | null = null;
  const promise = await postData(ORDER_ERROR, url, dispatch, data, true).then((response : any) => {
    order = response.data.order;
  });
  dispatch({
    type: ORDER_POST,
    payload: order,
  });
  return promise;
};

export const deleteOrderAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/order/${id}`;
  let order: IOrder | null = null;
  const promise = await deleteData(ORDER_ERROR, url, dispatch, true).then((response : any) => {
    order = response.data;
  });
  if (order) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'le brief a été supprimé',
      },
    });
  }
  return promise;
};

export const deleteFileAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/files/${id}`;
  let file: IFile | null = null;
  const promise = await deleteData(ORDER_ERROR, url, dispatch, true).then((response : any) => {
    file = response.data;
  });
  if (file) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'fichier supprimé',
      },
    });
  }
  return promise;
};

export const putFileAction = async (dispatch: DispatchType, id: string, data: { isNewFile: boolean; orderId: string; }): Promise<any> => {
  const url = `/files/${id}`;
  let order: IOrder | null | never = null;
  const promise = await putData(ORDER_PUT, ORDER_ERROR, url, dispatch, data, true).then((response : any) => {
    order = response.order;
  });
  dispatch({
    type: ORDER_PUT,
    payload: order,
  });
  return promise;
};

export const downloadFileAction = async (url : string, filename : string):Promise<any> => {
  const promise = await axios.get(url, {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, filename);
  });
  return promise;
};
