import React from 'react';
import { differenceInDays } from 'date-fns';
import styles from './Comment.module.scss';
import { IComment } from '../../types/question.types';

export default function Comment({ comment }:{ comment: IComment }) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const commentDate = new Date(comment?.createdAt);
  const date = new Date(commentDate);
  date.setHours(0, 0, 0, 0);
  const daysLeft : number | '' | undefined = comment.createdAt && differenceInDays(date, today);
  const locale = new Intl.DateTimeFormat('fr', { timeStyle: 'short' }).format(commentDate);
  return (
    <div className={`${styles.container} ${comment?.author?.role === 'Member' ? '' : styles['is-left']}`}>
      <div className={`${styles.icon} ${comment?.author?.role !== 'Member' ? styles.owner : ''}`}>
        {comment?.author?.profile?.lastName.charAt(0)}
        {comment?.author?.profile?.firstName.charAt(0)}
      </div>
      <div className={styles.info}>
        <p className={styles.profile}>
          <span className={styles.name}>
            {comment?.author?.profile?.firstName}
            {' '}
            {comment?.author?.profile?.lastName}
          </span>
          {' '}
          {daysLeft < 0
          && (
            <span>
              Il y a
              {' '}
              {daysLeft < 0 && Math.abs(daysLeft)}
              {' '}
              jour
              {daysLeft < -1 && 's'}
            </span>
          )}
          {daysLeft === 0
          && (
            <span>
              Aujourd&apos;hui
              {' '}
              {locale}
            </span>
          )}
          {' '}
        </p>
        <p className={styles.message}>
          {comment?.message}
        </p>
      </div>
    </div>
  );
}
