import React from 'react';
import logo from '../../assets/images/logo_white.png';
import styles from './Mobile.module.scss';

export default function Mobile() {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src={logo}
          alt="logo"
        />
      </div>
      <h4>L&apos;application n&apos;est pas disponible sur tablette ou mobile</h4>
    </div>
  );
}
