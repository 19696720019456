import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { useAppSelector, useAppDispatch } from './hooks/useRedux';
import { getProfileAction, logout } from './actions/auth';

import Loader from './components/Loader';
import Toaster from './components/Toaster';

// AUTH
import Login from './pages/Auth/Login';
import Forgot from './pages/Auth/Forgot';
import Reset from './pages/Auth/Reset';

// ADMIN
import Admin from './pages/Admin/Admin';
import Users from './pages/Admin/Users/Users';
import User from './pages/Admin/User/User';

// OWNER
import ProductsList from './pages/Products/ProductsList/ProductsList';
import Product from './pages/Products/Product/Product';

// CUSTOMER
import Customer from './pages/Customer/Customer';
import Catalog from './pages/Catalog/Catalog';
import OrdersList from './pages/Orders/OrdersList/OrdersList';
import OrderForm from './pages/Orders/OrderForm/OrderForm';
import Order from './pages/Orders/Order/Order';
import { SET_BACK_URL } from './actions/actions';

function Redirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state: any) => state.authReducer);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch);
    else {
      navigate('/login');
    }
  }, []);
  console.log(user);
  useEffect(() => {
    if (user?.role === 'Admin') {
      dispatch({
        type: SET_BACK_URL,
        payload: '/admin/order',
      });
      navigate('/admin/order');
    }
    if (user?.role === 'Owner' || user?.role === 'Moderator') {
      dispatch({
        type: SET_BACK_URL,
        payload: '/owner/order',
      });
      navigate('/owner/order');
    }
    if (user?.role === 'Member') {
      dispatch({
        type: SET_BACK_URL,
        payload: '/customer/order',
      });
      navigate('/customer/order');
    }
  }, [user]);

  return <Loader />;
}

function AppRoutes() {
  const { user, error } = useAppSelector((state: any) => state.authReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch);
  }, []);
  useEffect(() => {
    if (error === "Vous n'êtes pas autoriser à accéder à ce contenu") {
      logout(dispatch);
      navigate('/login');
    }
  }, [error]);
  return (
    <>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/reset-password/:token" element={<Reset />} />
        {user?.role === 'Admin' && (
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/order/:id" element={<Order />} />
            <Route path="/admin/order" element={<OrdersList />} />
            <Route path="/admin/archived" element={<OrdersList archived />} />
            <Route path="/admin/team" element={<Users role="Owner" />} />
            <Route path="/admin/team/add" element={<User role="Owner" />} />
            <Route path="/admin/team/:id" element={<User role="Owner" />} />
            <Route path="/admin/customer" element={<Users role="Member" />} />
            <Route path="/admin/customer/add" element={<User role="Member" />} />
            <Route path="/admin/customer/:id" element={<User role="Member" />} />
            <Route path="/admin/product" element={<ProductsList />} />
            <Route path="/admin/product/catalog" element={<Catalog />} />
            <Route path="/admin/product/add" element={<Product />} />
            <Route path="/admin/product/:id" element={<Product />} />
          </Route>
        )}
        {(user?.role === 'Owner' || user?.role === 'Moderator') && (
          <Route path="/owner" element={<Admin />}>
            <Route path="/owner/order/:id" element={<Order />} />
            <Route path="/owner/order" element={<OrdersList />} />
            <Route path="/owner/archived" element={<OrdersList archived />} />
            <Route path="/owner/product" element={<ProductsList />} />
            <Route path="/owner/product/catalog" element={<Catalog />} />
            <Route path="/owner/product/add" element={<Product />} />
            <Route path="/owner/product/:id" element={<Product />} />
          </Route>
        )}
        {user?.role === 'Member' && (
          <Route path="/customer" element={<Customer />}>
            <Route path="/customer/order" element={<OrdersList />} />
            <Route path="/customer/order/archived" element={<OrdersList archived />} />
            <Route path="/customer/order/:id" element={<Order />} />
            <Route path="/customer/order/form/:id" element={<OrderForm />} />
            <Route path="/customer/order/form" element={<OrderForm />} />
            <Route path="/customer/catalog" element={<Catalog />} />
          </Route>
        )}
        <Route path="*" element={<Redirect />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default AppRoutes;
