import { getData, postData, putData } from '.';
import { DispatchType } from '../reducers';
import { ICommentForm, IQuestion, IQuestionForm } from '../types/question.types';
import {
  GET_ORDER_QUESTIONS, QUESTION_ERROR, QUESTION_LOADING, QUESTION_POST, QUESTION_PUT,
} from './actions';

export const getOrderQuestionAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/question/${id}`;
  let questions = {};
  dispatch({
    type: QUESTION_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(QUESTION_ERROR, url, dispatch, true).then((response: any) => {
    questions = response.data.questions;
  });

  dispatch({
    type: GET_ORDER_QUESTIONS,
    payload: questions,
  });
  return promise;
};

export const postQuestionAction = async (dispatch: DispatchType, data: IQuestionForm): Promise<any> => {
  const url = '/question';
  dispatch({
    type: QUESTION_LOADING,
  });
  let question: IQuestion| null = null;
  const promise = await postData(QUESTION_ERROR, url, dispatch, data, true).then((response : any) => {
    question = response.data.question;
  });
  dispatch({
    type: QUESTION_POST,
    payload: question,
  });
  return promise;
};

export const putQuestionAction = async (dispatch: DispatchType, id: string, data: IQuestionForm): Promise<any> => {
  const url = `/question/${id}`;
  let question = null;
  const promise = await putData(QUESTION_PUT, QUESTION_ERROR, url, dispatch, data, true).then((response : any) => {
    question = response.question;
  });
  dispatch({
    type: QUESTION_PUT,
    payload: question,
  });
  return promise;
};

export const postCommentAction = async (dispatch: DispatchType, data: ICommentForm): Promise<any> => {
  const url = '/comment';
  dispatch({
    type: QUESTION_LOADING,
  });
  let question: IQuestion| null = null;
  const promise = await postData(QUESTION_ERROR, url, dispatch, data, true).then((response : any) => {
    question = response.data.question;
  });
  dispatch({
    type: QUESTION_PUT,
    payload: question,
  });
  return promise;
};
