import { format } from 'date-fns';
import React, { useRef } from 'react';
// import { Control } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../actions';
import { SET_TOAST } from '../../actions/actions';
import helpImg from '../../assets/images/help.png';
import helpIcon from '../../assets/images/icons/help.svg';
import { useAppDispatch } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import CustomConfirm from '../../lib/CustomConfirm/CustomConfirm';
import { IFile } from '../../types/file.types';
import { IFormat } from '../../types/order.types';
import styles from './OrdersForms.module.scss';
import { deleteOrderAction } from '../../actions/orders';
import { IOption } from '../../lib/HooksFormFields/StyledSelect/Creatable';

export default function Recap({
  activeStep,
  onSubmit,
  watchForm,
  url,
}:{
  activeStep: string | null,
  onSubmit: any,
  watchForm: any,
  url: any
}) {
  const navigate = useNavigate();
  const modalRef = useRef<IModalHandle>(null);
  const dispatch: DispatchType = useAppDispatch();
  const handleSubmitStep = async (step : string) => {
    if (step === '3') {
      navigate(`${url}?step=${step}`);
    }
    if (step === '4') {
      await onSubmit({ ...watchForm, status: 'new' });
      navigate('/customer/order');
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'success',
          message: 'la commande a été transmise',
        },
      });
    }
  };

  function deleteOrder() {
    if (watchForm?._id && modalRef?.current) {
      deleteOrderAction(dispatch, watchForm._id);
      modalRef.current.close();
      navigate('/customer/order');
    }
  }

  return (
    <div className={styles.container}>
      {activeStep === '4'
      && (
        <div className={styles.form}>

          <div className={`${styles.info} ${styles.bg}`}>
            <div>
              <label>Titre du brief</label>
              <p>{watchForm.title}</p>
            </div>
            <div>
              <label>Partage</label>
              <p>{watchForm.isPublic ? 'Public' : 'Privé'}</p>
            </div>
            <div>
              <label>Etablissement</label>
              <p>{watchForm?.structure?.label}</p>
            </div>
            <div>
              <label>
                Date limite de remise
              </label>
              <p>{watchForm.deliveryDate && format(new Date(watchForm.deliveryDate), 'dd/MM/yy')}</p>
            </div>
            <div>
              <label>Emails</label>
              <p>
                {watchForm?.emails?.map((e: IOption) => (
                  <span key={e.value}>
                    {e.value}
                    <br />
                  </span>
                ))}
              </p>
            </div>
          </div>

          <div className={`${styles.info}`}>
            <div>
              <label>Type de support</label>
              <p>{watchForm.type}</p>
            </div>
            {watchForm.printType
              && (
              <div>
                <label>Impression</label>
                <p>{watchForm?.printType?.label}</p>
              </div>
              )}
          </div>

          <div className={`${styles.formats}`}>
            {watchForm.formats
              && (
              <div>
                <label>Formats</label>
                  {watchForm.formats.map((f : IFormat) => (
                    <div key={`type-${f._id}`} className={styles['format-item']}>
                      <p>
                        {f?.type}
                        {f.quantity && ` - ${f.quantity} ex`}
                      </p>
                    </div>
                  ))}
              </div>
              )}
          </div>

          <label>Texte</label>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: watchForm.content }} />

          <label>Fichiers</label>
          <div className={styles.files}>
            <div className={`${styles.list} ${styles.recap}`}>
              {watchForm.files?.map((f: IFile) => (
                <a
                  key={f._id}
                  className={styles.file}
                  href={`${API_URL}/${f.path}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {f.name}
                </a>
              ))}
            </div>
          </div>
          <div className={styles['btn-container']}>
            <button
              className={`${styles.btn} ${styles.primary} ${styles.outlined}`}
              type="button"
              onClick={() => handleSubmitStep('3')}
            >
              Précèdent
            </button>
            <button
              className={`${styles.btn} ${styles.success}`}
              type="button"
              onClick={() => handleSubmitStep('4')}
            >
              Valider le brief
            </button>
            {watchForm?._id
            && (
              <button
                className={`${styles.btn} ${styles.danger}`}
                type="button"
                onClick={() => modalRef?.current && modalRef.current.open()}
              >
                Supprimer le brief
              </button>
            )}
          </div>
        </div>
      )}
      <div className={styles.help}>
        <img src={helpImg} alt="help" />
        <div className={styles.title}>
          <img src={helpIcon} alt="help-icon" />
          <h2>Aide</h2>
        </div>
        <ul>
          <li>
            Si un brief est noté public,
            <br />
            le résultat apparaitra dans le folio de la plateforme,
          </li>
          {/* <li>
            SI un brief est noté public,
            <br />
            le résultat apparaitra dans le folio de la plateforme,
          </li>
          <li>
            SI un brief est noté public,
            <br />
            le résultat apparaitra dans le folio de la plateforme,
          </li> */}
        </ul>
      </div>
      <ModalStandalone ref={modalRef}>
        <CustomConfirm
          message="Veuillez confirmer la suppression du brief"
          btnText="Confirmer la suppression"
          action={() => deleteOrder()}
        />
      </ModalStandalone>
    </div>
  );
}
