import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import { IOption } from '../../lib/HooksFormFields/StyledSelect';
import { IUser } from '../../types/auth.types';
import { IStructure } from '../../types/structure.types';
import styles from './UserRow.module.scss';

export default function UserRow({ user }: { user: IUser }) {
  const link = user.role === 'Member' ? `/admin/customer/${user._id}` : `/admin/team/${user._id}`;
  const {
    lists,
  } = useAppSelector((state: any) => state.orderReducer);
  const role = lists?.roles?.find((r: IOption) => r.value === user.role)?.label;
  return (
    <Link to={link} className={styles.row}>
      <div className={styles.col}>
        <p>
          {user?.profile.firstName}
          {' '}
          {user?.profile.lastName}
        </p>
      </div>
      <div className={styles.col}>
        <p>
          {user?.email}
        </p>
      </div>
      <div className={`${styles.col} ${user.role === 'Member' ? styles.structures : styles.role}`}>
        {user.role === 'Member' && user.structures.map((s: IStructure) => <p key={s._id}>{s.name}</p>)}
        {role && <p>{role}</p>}
      </div>
    </Link>
  );
}
