import React from 'react';
import { useForm } from 'react-hook-form';
import { postQuestionAction } from '../../actions/questions';
import { useAppDispatch } from '../../hooks/useRedux';
import { ErrorField, InputText, Textarea } from '../../lib/HooksFormFields';
import { DispatchType } from '../../reducers';
import { IQuestionForm } from '../../types/question.types';
import styles from './QuestionForm.module.scss';

export default function QuestionForm({ orderId, close } : { orderId: string, close: () => void }) {
  const dispatch: DispatchType = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IQuestionForm>({
    defaultValues: {
      orderId,
    },
  });

  function onSubmit(values: IQuestionForm) {
    postQuestionAction(dispatch, values);
    close();
  }

  return (
    <form className={styles.container}>
      <h2>Question</h2>
      <div className={styles['form-field']}>
        <InputText
          control={control}
          name="title"
          label="Titre"
          rules={{ required: 'le titre est requis' }}
          className="primary"
          placeholder="titre..."
        />
        {errors.title && <ErrorField message={errors.title.message} />}
      </div>
      <div className={styles['form-field']}>
        <Textarea
          control={control}
          name="message"
          label="Commentaire"
          rules={{ required: 'le message est requis' }}
          className="primary"
          placeholder="Tapez votre commentaire..."
        />
        {errors.message && <ErrorField message={errors.message.message} />}
      </div>
      <button
        className={`${styles.btn} ${styles.primary}`}
        onClick={handleSubmit(onSubmit)}
      >
        Envoyer
      </button>
    </form>
  );
}
