import { DispatchType } from '../reducers';
import { getData, postData } from './index';
import {
  STRUCTURE_LOADING,
  STRUCTURE_ERROR,
  STRUCTURE_GET_ALL,
  STRUCTURE_POST,
  SET_TOAST,
} from './actions';

import { IStructure } from '../types/structure.types';

export const getAllStructuresAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/structure';
  let structures = {};
  dispatch({
    type: STRUCTURE_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(STRUCTURE_ERROR, url, dispatch, true).then((response: any) => {
    structures = response.data.structures;
  });

  dispatch({
    type: STRUCTURE_GET_ALL,
    payload: structures,
  });
  return promise;
};

export const postStructureAction = async (dispatch: DispatchType, data: IStructure): Promise<any> => {
  const url = '/structure';
  dispatch({
    type: STRUCTURE_LOADING,
  });
  let structure: IStructure | null = null;
  const promise = await postData(STRUCTURE_ERROR, url, dispatch, data, true).then((response : any) => {
    structure = response.data.structure;
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'la structure a été crée',
    },
  });
  dispatch({
    type: STRUCTURE_POST,
    payload: structure,
  });
  return promise;
};
