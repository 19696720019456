import React, { useEffect } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { InputText, StyledSelect } from '../../lib/HooksFormFields';
import {
  IFormat, IOrder, IOrderList, RequiredRule,
} from '../../types/order.types';
import styles from './FormatRow.module.scss';

export default function FormatRow({
  format,
  form,
  requiredRule,
  lists,
  handleChangeFormats,
}:{
  form: IOrder,
  format: IFormat,
  requiredRule: RequiredRule,
  lists: IOrderList,
  handleChangeFormats: any,
}) {
  const options = [...lists.printsFormats, ...lists.webFormats];
  const {
    control,
    setValue,
    watch,
  } = useForm<IFormat>({
    defaultValues: {
      ...format,
      type: options.find((opt) => opt.value === format.type),
    },
  });

  console.log(format);

  const formatData = watch();

  function handleChangeData() {
    const array = form.formats || [];
    const foundIndex = array.findIndex((a) => a._id === format._id);
    if (foundIndex !== -1) {
      const updated: IFormat = { ...formatData, format: format.format };
      updated.type = formatData?.type?.value;
      updated.format = format.format;
      console.log(updated);
      if (JSON.stringify(array[foundIndex]) !== JSON.stringify(updated)) {
        array[foundIndex] = updated;
        handleChangeFormats(array);
      }
    }
  }

  useEffect(() => {
    if (formatData) {
      handleChangeData();
    }
  }, [formatData]);

  useEffect(() => {
    if (formatData?.type?.value === 'Visuel réseaux sociaux' && formatData.size === '') {
      setValue('size', '1080 x 1080 px');
    }
  }, [formatData?.type?.value]);

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <StyledSelect
          name="type"
          control={control}
          rules={requiredRule}
          placeholder="Séléctionner un format"
          options={formatData.format === 'print' ? lists.printsFormats : lists.webFormats}
        />
      </div>
      <div className={styles.input}>
        {formatData.format === 'print'
        && (
          <InputText
            name="quantity"
            className="primary"
            control={control}
            rules={requiredRule}
            placeholder="Quantité..."
          />
        )}
        {/* {formatData.format === 'web'
        && (
          <div className={styles.input} />
        )} */}
      </div>
      <button
        type="button"
        onClick={() => handleChangeFormats(form.formats?.filter((f) => f._id !== format._id))}
        className={styles.delete}
      >
        <IoIosCloseCircleOutline />
      </button>
    </div>
  );
}
