import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { DispatchType } from '../../reducers';
import { getOrdersCatalogAction, getOrdersListAction } from '../../actions/orders';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';

import { IOption } from '../../lib/HooksFormFields/StyledSelect';
import { IOrder } from '../../types/order.types';

import Loader from '../../components/Loader';
import CatalogCard from '../../components/CatalogCard/CatalogCard';
import { StyledSelect } from '../../lib/HooksFormFields';

import styles from './Catalog.module.scss';
import CatalogueSlider from '../../components/CatalogueSlider/CatalogueSlider';

interface IFilters {
  structure: IOption,
  sort: IOption,
  events: string[],
}

export default function Catalog() {
  const dispatch: DispatchType = useAppDispatch();
  const { catalog, isLoading, lists } = useAppSelector((state: any) => state.orderReducer);
  const [orders, setOrders] = useState([]);
  const [activeIndex, setActiveIndex] = useState<number|undefined>();

  const allStructures = { label: 'Toutes', value: 'all' };
  const sortOptions = [{ label: 'Date croissante', value: 'date-desc' }, { label: 'Date décroissante', value: 'date-asc' }];
  const {
    control,
    watch,
    setValue,
  } = useForm<IFilters>({
    defaultValues: {
      structure: allStructures,
      sort: sortOptions[1],
      events: [],
    },
  });
  const structure = watch('structure')?.value;
  const sort = watch('sort')?.value;
  const events = watch('events');

  function handleChangeEvents(value: string) {
    const found = events?.find((v) => v === value);
    if (found) {
      setValue('events', events.filter((v) => v !== value));
    } else {
      setValue('events', [...events, value]);
    }
  }

  useEffect(() => {
    getOrdersCatalogAction(dispatch);
    getOrdersListAction(dispatch);
  }, []);

  useEffect(() => {
    if (structure && sort) {
      const filtered = catalog?.filter((o: IOrder) => {
        if (structure === 'all') {
          return o;
        }
        return o?.structure?._id === structure;
      }).filter((o: IOrder) => {
        if (events.length === 0) {
          return o;
        }
        return events?.find((v) => v === o.eventType);
      });
      const sorted = filtered?.sort((a: IOrder, b: IOrder) => {
        const aDate = new Date(a.updatedAt).getTime();
        const bDate = new Date(b.updatedAt).getTime();
        if (sort === 'date-desc') {
          return aDate - bDate;
        }
        return bDate - aDate;
      });
      setOrders(sorted);
    }
  }, [structure, sort, events, catalog]);

  return (
    <div className={styles.container}>
      <Link
        to="/customer/order/form?step=1"
        className={`${styles.btn} ${styles.link} ${styles.primary}`}
      >
        Faire une demande
      </Link>

      {isLoading
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.events}>
                {lists?.events.filter((e: IOption) => catalog.find((c: IOrder) => c.eventType === e.value)).map((e: IOption) => (
                  <button
                    key={e.label}
                    type="button"
                    className={`${styles.btn} ${styles.primary} ${events?.find((v) => v === e.value) ? '' : styles.outlined}`}
                    onClick={() => handleChangeEvents(e.value)}
                  >
                    {e.label}
                  </button>
                ))}
              </div>
              <div className={styles.filters}>
                <div className={styles.filter}>
                  <p>
                    Filtrer par structure
                    {' '}
                    <span>:</span>
                  </p>
                  <div className={styles['container-field']}>
                    <StyledSelect
                      name="structure"
                      control={control}
                      rules={{ }}
                      className="secondary"
                      placeholder="Séléctionner..."
                      options={lists?.allStructures ? [allStructures, ...lists.allStructures] : []}
                    />
                  </div>
                </div>
                <div className={styles.filter}>
                  <p>
                    Trier par
                    {' '}
                    <span>:</span>
                  </p>
                  <div className={styles['container-field']}>
                    <StyledSelect
                      name="sort"
                      control={control}
                      className="secondary"
                      rules={{ }}
                      placeholder="Séléctionner..."
                      options={sortOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.grid}>
              {orders.map((o: IOrder, i: number) => <CatalogCard key={o._id} order={o} handleClick={() => setActiveIndex(i)} />)}
            </div>
            <CatalogueSlider
              orders={orders}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </div>
        )}
    </div>
  );
}
