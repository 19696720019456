import { Action } from '../types';
import {
  QUESTION_LOADING,
  QUESTION_ERROR,
  QUESTION_POST,
  QUESTION_PUT,
  GET_ORDER_QUESTIONS,
} from '../actions/actions';
import { QuestionState } from '../types/question.types';

const initialState: QuestionState = {
  questionsList: null,
  isLoading: false,
  error: null,
};

const questionsReducer = (
  state: QuestionState = initialState,
  action: Action,
): QuestionState => {
  let updatedState = { ...state };
  let errorMessage;
  const { payload } = action;
  const list = state.questionsList || [];
  const index = list.findIndex((item) => item._id === payload?._id);
  switch (action.type) {
    case QUESTION_LOADING:
      updatedState = {
        ...state,
        isLoading: true,
      };
      break;
    case QUESTION_PUT:
      if (index !== -1) {
        list[index] = { ...payload };
      }
      updatedState = {
        ...state,
        error: null,
        questionsList: list,
        isLoading: false,
      };
      break;
    case QUESTION_POST:
      updatedState = {
        ...state,
        error: null,
        questionsList: [payload, ...list],
        isLoading: false,
      };
      break;
    case GET_ORDER_QUESTIONS:
      updatedState = {
        ...state,
        error: null,
        questionsList: payload,
        isLoading: false,
      };
      break;
    case QUESTION_ERROR:
      errorMessage = action.payload;
      updatedState = {
        ...state,
        error: errorMessage,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default questionsReducer;
