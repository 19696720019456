import { DispatchType } from '../reducers';
import {
  deleteData, getData, postData, putData,
} from './index';
import {
  PRODUCT_LOADING,
  PRODUCT_ERROR,
  PRODUCT_GET_ALL,
  PRODUCT_GET,
  PRODUCT_PUT,
  PRODUCT_POST,
  SET_TOAST,
} from './actions';
import { IProduct } from '../types/product.types';

export const getProductsAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/product';
  let products = {};
  dispatch({
    type: PRODUCT_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(PRODUCT_ERROR, url, dispatch, true).then((response: any) => {
    products = response.data.products;
  });

  dispatch({
    type: PRODUCT_GET_ALL,
    payload: products,
  });
  return promise;
};

export const getProductAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/product/${id}`;
  let product = {};
  dispatch({
    type: PRODUCT_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(PRODUCT_ERROR, url, dispatch, true).then((response: any) => {
    product = response.data.product;
  });

  dispatch({
    type: PRODUCT_GET,
    payload: product,
  });
  return promise;
};

export const putProductAction = async (dispatch: DispatchType, id: string, data: IProduct): Promise<any> => {
  const url = `/product/${id}`;
  dispatch({
    type: PRODUCT_LOADING,
  });
  let product: IProduct | null | never = null;
  const promise = await putData(PRODUCT_PUT, PRODUCT_ERROR, url, dispatch, data, true).then((response : any) => {
    product = response.product;
  });
  dispatch({
    type: PRODUCT_PUT,
    payload: product,
  });
  return promise;
};

export const postProductAction = async (dispatch: DispatchType, data: IProduct): Promise<any> => {
  const url = '/product';
  dispatch({
    type: PRODUCT_LOADING,
  });
  let product: IProduct | null = null;
  const promise = await postData(PRODUCT_ERROR, url, dispatch, data, true).then((response : any) => {
    product = response.data.product;
  });
  dispatch({
    type: PRODUCT_POST,
    payload: product,
  });
  return promise;
};

export const deleteProductAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/product/${id}`;
  let product: IProduct | null = null;
  const promise = await deleteData(PRODUCT_ERROR, url, dispatch, true).then((response : any) => {
    product = response.data;
  });
  if (product) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'le produit a été supprimé',
      },
    });
  }
  return promise;
};
