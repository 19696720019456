import React, { KeyboardEvent } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import {
  InputEmail,
  ErrorField,
} from '../../../lib/HooksFormFields';

import styles from '../AuthForm.module.scss';

interface IResetPwdForm {
  errorMessage?: string | null,
  successMessage?: string | null,
  // eslint-disable-next-line no-unused-vars
  submit(data: any): any,
}

type FormValues = {
  email: string
};

function ForgotForm({
  successMessage,
  errorMessage,
  submit,
}: IResetPwdForm): JSX.Element {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  function onSubmit(data: FormValues) {
    submit(data);
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            className="login"
            control={control}
            required
            label="Identifiant"
            placeholder="Saissisez votre email..."
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        {!errors.email && errorMessage && !successMessage
          && (
          <p className={styles['message-error']}>
            {errorMessage}
          </p>
          )}
        {successMessage
          && (
            <p className={styles['message-success']}>
              {successMessage}
            </p>
          )}
      </div>
      <div className={styles['container-link']}>
        <NavLink to="/login">Se connecter</NavLink>
      </div>
      <div className={styles.btn}>
        <button
          className={styles.primary}
          onClick={handleSubmit(onSubmit)}
        >
          Valider
        </button>
      </div>
    </div>
  );
}

export default ForgotForm;
