import { Action } from '../types';
import {
  ORDER_LOADING,
  ORDER_ERROR,
  ORDER_GET_ALL,
  ORDER_GET,
  ORDER_PUT,
  ORDER_POST,
  ORDER_GET_LISTS,
  ORDER_CATALOG,
  SET_BACK_URL,
  SET_ORDERS_FILTERS,
} from '../actions/actions';
import { OrderState } from '../types/order.types';

const initialState: OrderState = {
  lists: null,
  order: null,
  ordersList: [],
  catalog: [],
  isLoading: false,
  error: null,
  backUrl: null,
  filters: null,
};

const orderReducer = (
  state: OrderState = initialState,
  action: Action,
): OrderState => {
  let updatedState = { ...state };
  let errorMessage;
  const orderIndex = state.ordersList.findIndex((o) => o._id === action.payload?._id);
  let { ordersList } = state;
  if (orderIndex !== -1) {
    ordersList = [...state.ordersList.filter((o) => o._id !== action.payload?._id), action.payload];
  }

  switch (action.type) {
    case SET_ORDERS_FILTERS:
      updatedState = {
        ...state,
        filters: { ...updatedState.filters, ...action.payload },
      };
      break;
    case SET_BACK_URL:
      updatedState = {
        ...state,
        backUrl: action.payload,
      };
      break;
    case ORDER_LOADING:
      updatedState = {
        ...state,
        isLoading: true,
      };
      break;
    case ORDER_GET_LISTS:
      updatedState = {
        ...state,
        lists: action.payload,
        isLoading: false,
      };
      break;
    case ORDER_PUT:
      updatedState = {
        ...state,
        error: null,
        ordersList,
        order: action.payload,
        isLoading: false,
      };
      break;
    case ORDER_POST:
      updatedState = {
        ...state,
        error: null,
        ordersList: [...updatedState.ordersList, action.payload],
        order: action.payload,
        isLoading: false,
      };
      break;
    case ORDER_GET:
      updatedState = {
        ...state,
        error: null,
        order: action.payload,
        isLoading: false,
      };
      break;
    case ORDER_CATALOG:
      updatedState = {
        ...state,
        catalog: action.payload,
        isLoading: false,
      };
      break;
    case ORDER_GET_ALL:
      updatedState = {
        ...state,
        ordersList: action.payload,
        isLoading: false,
      };
      break;
    case ORDER_ERROR:
      if (action.payload?.data?.error === 'That email address is already in use.') {
        errorMessage = 'Un compte est déjà associé à cet adresse e-mail.';
      } else {
        errorMessage = action.payload;
      }
      updatedState = {
        ...state,
        error: errorMessage,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default orderReducer;
