import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ORDER_POST } from '../../actions/actions';

import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';

import styles from './EventCard.module.scss';

export default function EventCard(): JSX.Element {
  const dispatch : DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const { lists } = useAppSelector((state) => state.orderReducer);
  const event = lists?.events[0];
  function createOrder() {
    dispatch({
      type: ORDER_POST,
      payload: {
        category: 'Évènement',
        eventType: event?.label,
      },
    });
    navigate('/customer/order/form?step=1');
  }

  return (
    <div className={styles.container}>
      <p>
        Passez commande
        <br />
        pour votre évènement
        {' '}
        &quot;
        {event?.label}
        &quot;
      </p>
      <button
        type="button"
        className={styles.primary}
        onClick={() => createOrder()}
      >
        Faire une demande
      </button>
    </div>
  );
}
