import React from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
// import { Control } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../actions';
import { deleteFileAction } from '../../actions/orders';
import helpImg from '../../assets/images/help.png';
import helpIcon from '../../assets/images/icons/help.svg';
import { useAppDispatch } from '../../hooks/useRedux';
import { ErrorField } from '../../lib/HooksFormFields';
import HtmlEditor from '../../lib/HooksFormFields/HtmlEditor/HtmlEditor';
import { DispatchType } from '../../reducers';
import { IFile } from '../../types/file.types';
import InputFile from '../InputFile/InputFile';
import styles from './OrdersForms.module.scss';

export default function Contents({
  activeStep,
  onSubmit,
  watchForm,
  setValue,
  setError,
  errors,
  url,
}:{
  activeStep: string | null,
  onSubmit: any,
  watchForm: any,
  setValue: any,
  setError: any,
  errors: any
  url: any
}) {
  const navigate = useNavigate();
  const dispatch: DispatchType = useAppDispatch();
  const handleSubmitStep = (step : string) => {
    if (step === '4') {
      if (!watchForm.content) {
        return setError('content', { type: 'custom', message: 'Le texte est requis' });
      }
      onSubmit(watchForm);
    }
    return navigate(`${url}?step=${step}`);
  };
  const files = watchForm.files.map((f: IFile) => f._id);
  async function deleteFile(id: string) {
    await deleteFileAction(dispatch, id);
    await onSubmit({ ...watchForm, files: files.filter((f: string) => f !== id) }).then((res: any) => {
      console.log(res);
    });
    // deleteFileAction(dispatch, id);
  }
  return (
    <div className={styles.container}>
      {activeStep === '3'
      && (
        <div className={styles.form}>
          <label>Texte</label>
          <HtmlEditor
            toolbarEnabled={false}
            onChange={(text: string) => setValue('content', text)}
            defaultValue={watchForm.content}
          />
          {errors.content && <ErrorField message={errors.content.message} />}
          <p />
          <label>Fichiers</label>
          <div className={styles.files}>
            <InputFile
              id="files"
              submit={(array: string[]) => onSubmit({ ...watchForm, files: [...files, ...array] })}
            />
            <div className={styles.list}>
              {watchForm.files?.map((f: IFile) => (
                <div key={f._id} className={styles.file}>
                  <a href={`${API_URL}/${f.path}`} target="_blank" rel="noreferrer">
                    {f.name}
                  </a>
                  <button
                    type="button"
                    onClick={() => deleteFile(f._id)}
                    className={styles.delete}
                  >
                    <IoIosCloseCircleOutline />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className={styles['btn-container']}>
            <button
              className={`${styles.btn} ${styles.primary} ${styles.outlined}`}
              type="button"
              onClick={() => handleSubmitStep('2')}
            >
              Précèdent
            </button>
            <button
              className={`${styles.btn} ${styles.primary}`}
              type="button"
              onClick={() => handleSubmitStep('4')}
            >
              Suivant
            </button>
          </div>
        </div>
      )}
      <div className={styles.help}>
        <img src={helpImg} alt="help" />
        <div className={styles.title}>
          <img src={helpIcon} alt="help-icon" />
          <h2>Aide</h2>
        </div>
        <ul>
          <li>
            Partez du principe que le graphiste n&apos;a aucune info, soyez clair(e) et précis(e).
          </li>
          <li>
            Il est essentiel de rappeler le contexte et l&apos;objectif de l&apos;outil à créer (exemple : Conquérir, fidéliser, remplir des créneaux…)
          </li>
          <li>
            Précisez le public cible (ex : jeunes actifs entre 25 et 30 ans, abonnés, famille...)
          </li>
          <li>
            Rédigez toutes les informations à faire apparaitre (exemple pour un event : date, heure et durée, tarif, lieu...).
          </li>
          <li>
            Pensez à mentionner vos éventuels partenaires et joindre leur logo.
          </li>
          <li>
            Prendre le temps de relire votre contenu avant envoi, vérifiez qu&apos;il soit complet et qu&apos;il ne contienne pas d&apos;erreur.
          </li>
        </ul>
      </div>
    </div>
  );
}
