import React from 'react';
import { format, differenceInDays } from 'date-fns';
import { IoCalendarClearOutline, IoDuplicateOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { IOrder } from '../../types/order.types';
import Status from '../Status/Status';
import styles from './OrderRow.module.scss';
import { IFile } from '../../types/file.types';
import { postOrderAction } from '../../actions/orders';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { IQuestion } from '../../types/question.types';

export default function OrderRow({
  order,
  role,
}:{
  role: string,
  order:IOrder
}): JSX.Element {
  const dispatch: DispatchType = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.authReducer);
  function getUserUrl(userRole:string) {
    switch (userRole) {
      case 'Admin':
        return `/admin/order/${order._id}`;
      case 'Member':
        return `/customer/order/${order._id}`;
      default:
        return `/owner/order/${order._id}`;
    }
  }
  const url = getUserUrl(role);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const daysLeft : number | '' | undefined = order?.deliveryDate && differenceInDays(new Date(order.deliveryDate), today);
  const files = order?.files && order?.files?.length > 0 ? order.files.map((f: IFile | any) => f._id) : [];

  const newComment = order?.questions && order?.questions.find((q: IQuestion) => q.newMessage && q.newMessage !== user?.role);

  function addDays(days:number) {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }

  function duplicateOrder(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    const createOrder = {
      ...order,
      bats: [],
      pastBats: [],
      files,
      invoice: null,
      thumbnail: null,
      status: 'draft',
      deliveryDate: addDays(10),
    };
    postOrderAction(dispatch, createOrder);
  }

  return (
    <Link to={url} className={styles.row}>
      <div className={styles.col}>
        {newComment
        && <div className={styles.indicator} />}
        <p>
          {order.title}
        </p>
      </div>
      <div className={`${styles.col} ${styles.days}`}>
        {daysLeft
          && (
          <p className={daysLeft > 0 ? '' : styles['is-late']}>
            <span>
              {daysLeft >= 0 && daysLeft}
              {daysLeft < 0 && Math.abs(daysLeft)}
            </span>
            {' '}
            {daysLeft > 0 && 'jours restants'}
            {daysLeft === 0 && "Aujourd'hui"}
            {daysLeft < 0 && 'jours en retard'}
          </p>
          )}
      </div>
      <div className={`${styles.col} ${styles.date}`}>
        {order.deliveryDate
          && (
          <>
            <IoCalendarClearOutline size={20} />
            {' '}
            <p>{order.deliveryDate && format(new Date(order.deliveryDate), 'dd/MM/yy')}</p>
          </>
          )}
      </div>
      <div className={`${styles.col} ${styles.structures}`}>
        <p>{order?.structure?.name}</p>
      </div>
      <div className={`${styles.col} ${styles.status}`}>
        {order.status === 'archived' && role === 'Member'
          ? (
            <button
              type="button"
              onClick={(e) => duplicateOrder(e)}
            >
              <IoDuplicateOutline />
            </button>
          )
          : (
            <div className={styles.border}>
              <Status status={order.status} isRow />
            </div>
          )}
      </div>
    </Link>
  );
}
