import { Action } from '../types';
import {
  USER_LOADING,
  USER_ERROR,
  USER_GET_ALL,
  USER_GET,
  USER_PUT,
  USER_POST,
} from '../actions/actions';
import { UserState } from '../types/user.types';

const initialState: UserState = {
  user: null,
  usersList: null,
  isLoading: false,
  error: null,
};

const userReducer = (
  state: UserState = initialState,
  action: Action,
): UserState => {
  let updatedState = { ...state };
  let errorMessage;

  switch (action.type) {
    case USER_LOADING:
      updatedState = {
        ...state,
        isLoading: true,
      };
      break;
    case USER_PUT:
      updatedState = {
        ...state,
        error: null,
        user: action.payload,
        isLoading: false,
      };
      break;
    case USER_POST:
      updatedState = {
        ...state,
        error: null,
        user: action.payload,
        isLoading: false,
      };
      break;
    case USER_GET:
      updatedState = {
        ...state,
        error: null,
        user: action.payload,
        isLoading: false,
      };
      break;
    case USER_GET_ALL:
      updatedState = {
        ...state,
        user: null,
        usersList: action.payload,
        isLoading: false,
      };
      break;
    case USER_ERROR:
      if (action.payload?.data?.error === 'That email address is already in use.') {
        errorMessage = 'Un compte est déjà associé à cet adresse e-mail.';
      } else {
        errorMessage = action.payload;
      }
      updatedState = {
        ...state,
        error: errorMessage,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default userReducer;
