import { format } from 'date-fns';
import React from 'react';
import { FiFolder } from 'react-icons/fi';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { API_URL } from '../../actions';
import { putOrderAction } from '../../actions/orders';
import picture from '../../assets/images/picture.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { IFile } from '../../types/file.types';
import { IOrder } from '../../types/order.types';
import { IQuestion } from '../../types/question.types';
import Status from '../Status/Status';
import styles from './OrderCard.module.scss';

export default function OrderCard({
  order,
}:{
  order:IOrder
}): JSX.Element {
  const dispatch : DispatchType = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.authReducer);
  const newComment = order?.questions && order?.questions.find((q: IQuestion) => q.newMessage && q.newMessage !== user?.role);

  const url = order.status === 'draft' ? `/customer/order/form/${order._id}?step=4` : `/customer/order/${order._id}`;
  const files : IFile[] | any[] = order?.files && order?.files?.length > 0 ? order?.files : [];
  const bats : IFile[] | any[] = order?.bats && order?.bats?.length > 0 ? order?.bats : [];
  const img : IFile = bats.find((f: IFile) => f.mimetype === 'image/png' || f.mimetype === 'image/jpeg') || files.find((f: IFile) => f.mimetype === 'image/png' || f.mimetype === 'image/jpeg');

  function archiveOrder(e: any, id : string) {
    e.preventDefault();
    putOrderAction(dispatch, id, {
      status: 'archived',
    });
  }

  return (
    <Link to={url} className={styles.container}>
      <div className={styles.status}>
        <Status status={order.status} />
      </div>
      {img?._id
        ? (
          <div className={styles.img}>
            <img src={`${API_URL}/${img.path}`} alt="img" />
          </div>
        )
        : (
          <div className={styles['img-default']}>
            <img src={picture} alt="img" />
          </div>
        )}
      <div className={styles.infos}>
        {newComment
        && <div className={styles.indicator} />}
        <h3>{order.title}</h3>
        {order.deliveryDate
        && (
        <p>
          <IoCalendarClearOutline />
          Remise :
          {' '}
          {order.deliveryDate && format(new Date(order.deliveryDate), 'dd/MM/yy')}
        </p>
        )}
        <p className={styles.structure}>{order?.structure?.name}</p>
        {}
        {order.status === 'delivered' && order._id && (
          <button
            type="button"
            onClick={(e) => archiveOrder(e, order._id)}
            className={styles.archived}
          >
            <span>Archiver</span>
            <FiFolder />
          </button>
        )}
      </div>
    </Link>
  );
}
