import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styles from './input-password.module.scss';
// At least 8 characters
// On digit
// On uppercase
// On special characters

interface IInputPassword {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  required?: boolean,
  label?: string | null,
  inline?: boolean,
  widthLabel?: string | null,
  placeholder?: string,
  requiredMessage?: string,
  className?: string,
}

function InputPassword({
  name,
  control,
  required,
  widthLabel = null,
  inline = false,
  label = null,
  requiredMessage = '',
  placeholder,
  className,
}: IInputPassword): JSX.Element {
  const [type, setType] = useState('password');

  function getRequiredMessage() {
    return requiredMessage || 'Le champs mot de password est obligatoire';
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? getRequiredMessage() : false,
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^/\\+\-|=!*?()@%&]).{8,}$/,
          message: 'The password should have at least 8 characters, on digit, on uppercase, and one special characters #$^/+-\\=|!*?()@%& ',
        },
      }}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `${className ? styles[className] : ''}  ${styles['container-input-password']} ${styles.inline}` : `${className ? styles[className] : ''}  ${styles['container-input-password']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <div className={styles['container-input']}>
            <input
              ref={ref}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              className={styles.input}
              placeholder={placeholder}
              type={type}
              autoComplete="on"
            />
            <div
              className={styles['container-icon']}
              onClick={() => setType((t) => (t === 'password' ? 'text' : 'password'))}
            >
              {type !== 'password' ? <AiOutlineEye size={24} /> : <AiOutlineEyeInvisible size={24} /> }
            </div>
          </div>
        </div>

      )}
    />

  );
}

export default InputPassword;
