import React from 'react';
import { useAppSelector } from '../../hooks/useRedux';
import { IOption } from '../../lib/HooksFormFields/StyledSelect/Creatable';
import styles from './Status.module.scss';

export default function Status({ status, isRow = false }:{ status: string, isRow?: boolean }) {
  const { lists } = useAppSelector((state: any) => state.orderReducer);
  const label = lists?.status.find((s : IOption) => s.value === status)?.label;
  return (
    <div className={styles.container}>
      {!isRow
      && <div className={`${styles.indicator} ${styles[status]}`} />}
      <p>{label}</p>
      {isRow
      && <div className={`${styles.indicator} ${styles[status]}`} />}
    </div>
  );
}
