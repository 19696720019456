/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from 'react-select';
import {
  Controller,
} from 'react-hook-form';
import styles from './styled-select.module.scss';
import { style1, style2 } from './style';

export interface IOption {
  value: string,
  label: string,
  structure?: string,
}

interface ISelect {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any,
  inline?: Boolean | undefined | null,
  isMulti?: Boolean | undefined | null,
  placeholder: string,
  label?: string | null,
  className?: string | null,
  style?: string | null,
  options: IOption[],
}

function StyledSelect({
  name,
  control,
  rules,
  isMulti = false,
  inline = false,
  placeholder = '',
  className = 'primary',
  label,
  options = [],
}: ISelect) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${className ? styles[className] : ''} ${styles.inline}` : `${styles['container-select']} ${className ? styles[className] : ''}`}>
          { label && <label>{label}</label>}
          <div className={styles.input}>
            <Select
              {...field}
              options={options}
              className={`${styles.select}`}
              isMulti={!!isMulti}
              placeholder={placeholder}
              styles={className === 'primary' ? style1 : style2}
            />
          </div>
        </div>
      )}
    />
  );
}

export default StyledSelect;
