import React, { useEffect, KeyboardEvent, useRef } from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { AiFillDelete } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../../../actions/index';
import { DispatchType } from '../../../reducers';
import {
  ErrorField,
  InputText,
  StyledSelect,
} from '../../../lib/HooksFormFields';
import { IOption } from '../../../lib/HooksFormFields/StyledSelect';
import { IProduct } from '../../../types/product.types';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import Loader from '../../../components/Loader';
import InputFile from '../../../components/InputFile/InputFile';
import ModalStandalone, { IModalHandle } from '../../../lib/ModalStandalone';
import {
  getProductAction, putProductAction, postProductAction, deleteProductAction,
} from '../../../actions/products';
import { getOrdersListAction, deleteFileAction } from '../../../actions/orders';
import CustomConfirm from '../../../lib/CustomConfirm/CustomConfirm';

import styles from './Product.module.scss';

export default function Product() {
  const dispatch: DispatchType = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const modalRef = useRef<IModalHandle>(null);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IProduct>();
  const { lists } = useAppSelector((state: any) => state.orderReducer);
  const { product, isLoading } = useAppSelector((state: any) => state.productReducer);
  const { user } = useAppSelector((state: any) => state.authReducer);
  const linkBack = user.role === 'Admin' ? '/admin/product' : '/owner/product';
  const requiredRule = { required: 'Ce champs est requis' };
  const watchForm = watch();
  const eventType = watch('eventType')?.value;

  function onSubmit(data: IProduct) {
    const productData = {
      ...watchForm,
      ...data,
      eventType,
      thumbnail: watchForm?.thumbnail?._id || data?.thumbnail,
    };
    if (productData?.thumbnail) {
      productData.thumbnail = productData.thumbnail?._id || productData.thumbnail;
    }
    if (product?._id) {
      putProductAction(dispatch, product?._id, productData);
    } else {
      postProductAction(dispatch, productData);
    }
  }

  function deleteFile(id: string) {
    if (watchForm.thumbnail?._id === id) {
      deleteFileAction(dispatch, id);
      return onSubmit({ ...watchForm, thumbnail: null });
    }
    return null;
  }

  function addFile(id: string) {
    if (watchForm?.thumbnail?._id) {
      deleteFileAction(dispatch, watchForm?.thumbnail?._id);
    }
    if (watchForm.title) {
      return onSubmit({ ...watchForm, thumbnail: id });
    }
    return handleSubmit(onSubmit)();
  }

  function deleteProduct() {
    if (product?._id && modalRef?.current) {
      deleteProductAction(dispatch, product._id);
      modalRef.current.close();
      navigate(linkBack);
    }
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  useEffect(() => {
    getOrdersListAction(dispatch);
  }, []);

  useEffect(() => {
    if (params?.id) {
      getProductAction(dispatch, params.id);
    }
  }, [params?.id]);

  useEffect(() => {
    if (product?._id && lists?.events?.length) {
      const formData = { ...product, eventType: lists?.events.find((e: IOption) => e.value === product.eventType) };
      reset(formData);
    }
  }, [product, lists?.events]);

  return (
    <div className={styles.container}>
      <header>
        <Link to={linkBack} className={styles.back}>
          <HiOutlineArrowLeft size={20} />
          <p>Retour</p>
        </Link>
        <h2>
          {product?._id
            ? (
              <span>
                {product.title}
              </span>
            )
            : (
              <span>
                Nouveau projet
              </span>
            )}
        </h2>
      </header>
      {isLoading
        ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        )
        : (
          <form autoComplete="off" className={styles.form}>
            <div className={styles.row} onKeyDown={handlePressEnter}>
              <div className={styles.col}>
                {product?.thumbnail?.path
                  ? (
                    <div className={styles.file}>
                      <div className={styles.img}>
                        <img src={`${API_URL}/${product.thumbnail.path}`} alt="img" />
                      </div>
                      <div className={styles.actions}>
                        <button
                          type="button"
                          onClick={() => deleteFile(product?.thumbnail._id)}
                          className={styles.delete}
                        >
                          <AiFillDelete size={20} />
                        </button>
                      </div>
                    </div>
                  )
                  : (
                    <InputFile
                      id="thumbnail"
                      className="large"
                      submit={(array: string[]) => addFile(array[0])}
                    />
                  )}
              </div>
              <div className={styles.col}>
                <div className={styles['container-field']}>
                  <InputText
                    name="title"
                    className="primary"
                    control={control}
                    rules={requiredRule}
                    label="Titre du brief"
                    placeholder="titre..."
                  />
                  {errors?.title && <ErrorField message={errors.title.message} />}
                </div>

                <div className={styles['container-field']}>
                  <StyledSelect
                    name="eventType"
                    control={control}
                    rules={requiredRule}
                    label="Type d’évenement"
                    placeholder="Séléctionner..."
                    options={lists?.events || []}
                  />
                  {errors.eventType && <ErrorField message={errors.eventType.message} />}
                </div>
                <div className={styles['btn-container']}>
                  <button
                    type="button"
                    className={`${styles.btn} ${styles.primary}`}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Enregister
                  </button>
                  {product?._id
                && (
                <button
                  type="button"
                  className={`${styles.btn} ${styles.danger}`}
                  onClick={() => modalRef?.current && modalRef.current.open()}
                >
                  Supprimer le projet
                </button>
                )}
                </div>
              </div>
            </div>
          </form>
        )}
      <ModalStandalone ref={modalRef}>
        <CustomConfirm
          message="Veuillez confirmer la suppression du projet"
          btnText="Confirmer la suppression"
          action={() => deleteProduct()}
        />
      </ModalStandalone>
    </div>
  );
}
