import React, { useState } from 'react';
import styles from './QuestionsList.module.scss';
import { IQuestion } from '../../types/question.types';
import QuestionRow from '../QuestionRow/QuestionRow';

export default function QuestionLists({ questions }:{ questions: IQuestion[]}) {
  const [questionIsOpen, setQuestionIsOpen] = useState(false);
  return (
    <div className={`${styles.container} ${questionIsOpen ? styles.active : ''}`}>
      <div className={styles.title}>
        <h3 className={styles.label}>
          {questions.length}
          {' '}
          échange
          {questions.length > 1 ? 's' : ''}
        </h3>
        <button
          type="button"
          className={questionIsOpen ? styles.active : ''}
          onClick={() => setQuestionIsOpen(!questionIsOpen)}
        >
          <div className={styles.minus} />
          <div className={styles.plus} />
        </button>
      </div>
      <div className={styles.content} style={{ display: questionIsOpen ? 'none' : 'block' }}>
        <div className={styles.list}>
          {questions.map((q) => <QuestionRow key={q._id} question={q} />)}
        </div>
      </div>
    </div>
  );
}
