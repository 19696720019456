import { Action } from '../types';
import {
  AUTH_LOGIN,
  AUTH_GET_PROFILE,
  AUTH_PUT_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
  SET_TOAST,
  AUTH_REGISTER,
} from '../actions/actions';
import { AuthState } from '../types/auth.types';

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
  toast: null,
};

const authReducer = (
  state: AuthState = initialState,
  action: Action,
): AuthState => {
  let updatedState = { ...state };
  let errorMessage;

  switch (action.type) {
    case AUTH_REGISTER:
      updatedState = {
        ...state,
        user: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_LOGIN:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('id', action.payload.user._id);

      updatedState = {
        ...state,
        user: action.payload.user,
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_PUT_PROFILE:
      updatedState = {
        ...state,
        user: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_GET_PROFILE:
      updatedState = {
        ...state,
        user: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('id');

      updatedState = { ...state, user: null };
      break;
    case AUTH_LOADING:
      updatedState = { ...state, isLoading: false };
      break;
    case SET_TOAST:
      updatedState = { ...state, toast: action.payload };
      break;
    case AUTH_RESET_ERROR:
      updatedState = { ...state, error: null };
      break;
    case AUTH_ERROR:
      errorMessage = 'Vous n\'êtes pas autoriser à accéder à ce contenu';
      if (action.payload?.config?.url.includes('auth/login')) {
        errorMessage = 'Adresse e-mail et/ou mot de passe incorrects.';
      } else if (action.payload?.config?.url.includes('auth/forgot-password')) {
        errorMessage = 'Aucun compte n\'est associé à cet adresse e-mail.';
      } else if (action.payload?.data?.error === 'That email address is already in use.') {
        errorMessage = 'Un compte est déjà associé à cet adresse e-mail.';
      } else if (action.payload?.data?.error === 'confirm password is invalid') {
        errorMessage = "La confirmation du mot de passe n'est pas valide";
      } else if (action.payload?.data?.error === 'Your token has expired. Please attempt to reset your password again.') {
        errorMessage = 'Le mot passe n\'a pas pu être changé, rendez-vous sur la page "mot de passe oublié" pour essayer à nouveau';
      }
      console.log(errorMessage);
      updatedState = { ...state, error: errorMessage };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default authReducer;
