import { DispatchType } from '../reducers';
import {
  deleteData, getData, postData, putData,
} from './index';
import {
  USER_LOADING,
  USER_ERROR,
  USER_GET_ALL,
  USER_GET,
  USER_PUT,
  SET_TOAST,
  USER_POST,
} from './actions';
import { IUser } from '../types/auth.types';

export const getAllUsersAction = async (dispatch: DispatchType, role: string): Promise<any> => {
  const url = `/users/all/${role}`;
  let user = {};
  dispatch({
    type: USER_LOADING,
  });
  console.log(role);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(USER_ERROR, url, dispatch, true).then((response: any) => {
    user = response.data.users;
  });

  dispatch({
    type: USER_GET_ALL,
    payload: user,
  });
  return promise;
};

export const getUserAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/users/${id}`;
  let user = {};
  dispatch({
    type: USER_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(USER_ERROR, url, dispatch, true).then((response: any) => {
    user = response.data.user;
  });

  dispatch({
    type: USER_GET,
    payload: user,
  });
  return promise;
};

export const putUserAction = async (dispatch: DispatchType, id: string, data: IUser): Promise<any> => {
  const url = `/users/${id}`;
  dispatch({
    type: USER_LOADING,
  });
  let user: IUser | null = null;
  const promise = await putData(USER_PUT, USER_ERROR, url, dispatch, data, true).then((response : any) => {
    user = response.user;
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'utilisteur a été modifé",
    },
  });
  dispatch({
    type: USER_PUT,
    payload: user,
  });
  return promise;
};

export const postUserAction = async (dispatch: DispatchType, data: IUser): Promise<any> => {
  const url = '/users';
  dispatch({
    type: USER_LOADING,
  });
  let user: IUser | null = null;
  const promise = await postData(USER_ERROR, url, dispatch, data, true).then((response : any) => {
    user = response.data.user;
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "l'utilisteur a été crée",
    },
  });
  dispatch({
    type: USER_POST,
    payload: user,
  });
  return promise;
};

export const deleteUserAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/users/${id}`;
  let user: IUser | null = null;
  const promise = await deleteData(USER_ERROR, url, dispatch, true).then((response : any) => {
    console.log(response);
    user = response.data;
  });
  if (user) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: "l'utilisteur a été supprimé",
      },
    });
  }
  return promise;
};
