import { Action } from '../types';
import {
  PRODUCT_LOADING,
  PRODUCT_ERROR,
  PRODUCT_GET_ALL,
  PRODUCT_GET,
  PRODUCT_PUT,
  PRODUCT_POST,
} from '../actions/actions';
import { ProductState } from '../types/product.types';

const initialState: ProductState = {
  product: null,
  productsList: [],
  isLoading: false,
  error: null,
};

const productReducer = (
  state: ProductState = initialState,
  action: Action,
): ProductState => {
  let updatedState = { ...state };
  const productIndex = state.productsList.findIndex((o) => o._id === action.payload?._id);
  let { productsList } = state;
  if (productIndex !== -1) {
    productsList = [...state.productsList.filter((o) => o._id !== action.payload?._id), action.payload];
  }

  switch (action.type) {
    case PRODUCT_LOADING:
      updatedState = {
        ...state,
        isLoading: true,
      };
      break;
    case PRODUCT_PUT:
      updatedState = {
        ...state,
        error: null,
        productsList,
        product: action.payload,
        isLoading: false,
      };
      break;
    case PRODUCT_POST:
      updatedState = {
        ...state,
        error: null,
        productsList: [...updatedState.productsList, action.payload],
        product: action.payload,
        isLoading: false,
      };
      break;
    case PRODUCT_GET:
      updatedState = {
        ...state,
        error: null,
        product: action.payload,
        isLoading: false,
      };
      break;
    case PRODUCT_GET_ALL:
      updatedState = {
        ...state,
        product: null,
        productsList: action.payload,
        isLoading: false,
      };
      break;
    case PRODUCT_ERROR:
      updatedState = {
        ...state,
        error: action.payload,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default productReducer;
