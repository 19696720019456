import React, { useEffect } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { NavLink, useNavigate } from 'react-router-dom';
import { DispatchType } from '../../reducers';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import {
  logout,
} from '../../actions/auth';
import imgOrder from '../../assets/images/icons/demande.svg';
import imgArchived from '../../assets/images/icons/archive.svg';
import imgGraphiste from '../../assets/images/icons/graphiste.svg';
import imgCustomer from '../../assets/images/icons/clients.svg';
import logo from '../../assets/images/logo_blue.png';
import styles from './Nav.module.scss';
import { SET_BACK_URL, SET_ORDERS_FILTERS } from '../../actions/actions';
import { getOrdersAction } from '../../actions/orders';
import { IOrder } from '../../types/order.types';

export default function Nav() {
  const { user } = useAppSelector((state: any) => state.authReducer);
  const {
    filters, ordersList,
  } = useAppSelector((state: any) => state.orderReducer);
  const dates = ordersList?.map((d: IOrder) => d)
    .sort((a: IOrder, b: IOrder) => new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime())
    .map((d: IOrder) => new Date(d.deliveryDate));
  const dispatch: DispatchType = useAppDispatch();

  const navigate = useNavigate();
  function handleLogout() {
    logout(dispatch);
    navigate('/login');
  }

  function setBackUrl(url: string) {
    console.log(dates);
    if (filters && dates.length > 0) {
      dispatch({
        type: SET_ORDERS_FILTERS,
        payload: {
          ...filters,
          dates: [new Date(dates[0]), new Date(dates[dates.length - 1])],
        },
      });
    }
    dispatch({
      type: SET_BACK_URL,
      payload: url,
    });
  }

  useEffect(() => {
    getOrdersAction(dispatch);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.background}>
        <div className={styles.links}>
          {(user.role === 'Owner' || user.role === 'Moderator')
          && (
            <>
              <NavLink
                to="/owner/order"
                end
                onClick={() => setBackUrl('/owner/order')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgOrder} alt="commande" />
                {' '}
                Mes demandes
              </NavLink>
              <NavLink
                to="/owner/archived"
                onClick={() => setBackUrl('/owner/archived')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgArchived} alt="archivés" />
                {' '}
                Archives
              </NavLink>
              <NavLink
                to="/owner/product"
                onClick={() => setBackUrl('/owner/product')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgArchived} alt="catalog" />
                {' '}
                Catalogue
              </NavLink>
            </>
          )}
          {user.role === 'Admin'
          && (
            <>
              <NavLink
                to="/admin/order"
                end
                onClick={() => setBackUrl('/admin/order')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgOrder} alt="commande" />
                {' '}
                Mes demandes
              </NavLink>
              <NavLink
                to="/admin/archived"
                onClick={() => setBackUrl('/admin/archived')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgArchived} alt="archivés" />
                {' '}
                Archives
              </NavLink>
              <NavLink
                to="/admin/team"
                onClick={() => setBackUrl('/admin/team')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgGraphiste} alt="graphiste" />
                {' '}
                Mon équipe
              </NavLink>
              <NavLink
                to="/admin/customer"
                onClick={() => setBackUrl('/admin/customer')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgCustomer} alt="clients" />
                {' '}
                Mes clients
              </NavLink>
              <NavLink
                to="/admin/product"
                onClick={() => setBackUrl('/admin/product')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgArchived} alt="Catalogue" />
                {' '}
                Catalogue
              </NavLink>
            </>
          )}
          {user.role === 'Member'
          && (
            <>
              <NavLink
                to="/customer/order"
                end
                onClick={() => setBackUrl('/customer/order')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgOrder} alt="commande" />
                {' '}
                Mes demandes
              </NavLink>
              <NavLink
                to="/customer/order/archived"
                onClick={() => setBackUrl('/customer/order/archived')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgArchived} alt="archivés" />
                {' '}
                Archives
              </NavLink>
              <NavLink
                to="/customer/catalog"
                onClick={() => setBackUrl('/customer/catalog')}
                className={({ isActive }) => (isActive ? styles.active : '')}
              >
                <img src={imgArchived} alt="archivés" />
                {' '}
                Catalogue
              </NavLink>
            </>
          )}
        </div>

        <div className={styles.profile}>
          <p>
            {user?.profile?.firstName}
            {' '}
            {user?.profile?.lastName}
          </p>
          <button
            onClick={() => handleLogout()}
            className={styles.logout}
          >
            <FiLogOut />
          </button>
        </div>
      </div>
    </div>
  );
}
