import React from 'react';
import { TbWorld, TbFile } from 'react-icons/tb';
import { API_URL } from '../../actions';
import { IOrder } from '../../types/order.types';
import styles from './CatalogCard.module.scss';

export default function CatalogCard({
  order,
  handleClick,
}:{
  order:IOrder,
  handleClick: () => void
}): JSX.Element {
  return (
    <div
      onClick={() => handleClick()}
      className={styles.container}
    >
      {order.thumbnail
        && (
          <div className={styles.img}>
            <img src={`${API_URL}/${order.thumbnail.path}`} alt="img" />
          </div>
        )}
      <div className={styles.infos}>
        <p>{order?.eventType}</p>
        <div className={styles.icon}>
          {order?.type === 'Print' ? <TbFile /> : <TbWorld />}
        </div>
      </div>
    </div>
  );
}
